import React, { useEffect } from 'react';
import Nav from "./Component/Partials/Nav";
import LeftSide from "./Component/Partials/LeftSide";
import RightSide from "./Component/Partials/RightSide";
import MobileNav from "./Component/Partials/MobileNav";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Agent from './Network/Agent';
import { USER_SELF_FETCH_SUCCESS } from './Reducer/UserSelfReducer';
import PublicRightSide from './Component/Partials/PublicRightSide';
import { GET_DATETIME, GET_TIMEZONE } from './Reducer/TimeZoneSlice';

const App = () => {
    const location = useLocation();
    let { id } = useParams();
    let { room } = useParams();
    const dispatch = useDispatch();
    const auth_user = useSelector((state) => state.authReducer)
    useEffect(() => {
        dispatch(USER_SELF_FETCH_SUCCESS(JSON.parse(localStorage.getItem("user_data"))))
        Agent.User.getData(dispatch)
        // Agent.Notification.latest(dispatch)
        Agent.Message.chatList(dispatch)
        const currentDate = new Date().toLocaleString();
        dispatch(GET_DATETIME(currentDate))
        // Get the user's timezone
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        dispatch(GET_TIMEZONE(timeZone))
    }, []);

    const user = useSelector((state) => state.userReducer?.data)
    if (user) {
        localStorage.setItem("profile_img", user.avatar)
        localStorage.setItem("profile_name", user.name)
        localStorage.setItem("user_data", JSON.stringify(user))
        dispatch(USER_SELF_FETCH_SUCCESS(user))
    }


    return (

        <>

            <div className={`main-content-wrapper d-flex flex-column ${location.pathname == `/stories/${id}`?'story-main-content-wrapper ':''}`}>
                <Nav />

                {location.pathname == `/meeting-details/${room}` ?
                    <Outlet />
                    :
                    location.pathname == `/stories/${id}` ?
                    <Outlet />
                    :
                    location.pathname == `/shorts/${id}` ?
                    <Outlet />
                    :
                    <>
                        <LeftSide />

                        {auth_user?.accessToken == false?
                            <>
                           <PublicRightSide/>
                            </>
                            :

                            <>
                                {location.pathname === '/all-story' || location.pathname === '/videos' || location.pathname === `/video/${id}` ? '' :

                                    <RightSide />

                                }
                            </>

                        }

                        <div
                            className={`content-page-box-area  ${location.pathname === '/all-story' || location.pathname === '/videos' || location.pathname === `/video/${id}` ? 'content-box-margin-l' : 'content-box-margin-lr'}`}>
                            <div className="container-fluid">

                                <Outlet />

                            </div>
                        </div>
                    </>
                }
            </div>


            {/*mobile nav start*/}
            <div className="mobile-bottom-nav d-xl-none fixed-bottom shadow-lg mobile-menu">
                <MobileNav />
            </div>


        </>
    );
}

export default App;

