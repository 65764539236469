import API from "../Network/API";
import React, {Component, Fragment, useEffect, useState, memo} from "react";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Placeholder1 from "../Network/Placeholder1";
import PlaceholderSuggestFriend from "../Network/PlaceholderSuggestFriend";
toast.configure();
export default function ConnectSuggest() {
    const [users, setUsers] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [div_id, setDivID] = useState(0);
    const [progress, setProgress] = useState(false);
    const [demo_data, setDatas] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}]);
    useEffect(() => {
        getData();
    }, []);

    const _sendFriendRequest =(id)=>{
       setLoading(true)
        setDivID(id)  //user id
        API.post("/friend_request",{
            user_id: id,
        }).then((response) => {
            setLoading(false)

            if (response.data.success === true) {
                toast.success("Request sent");
                getData();
            }
        }).catch((error) => {
            if( error.response ){
                toast.success("something went wrong!");
            }
        });
    }

    const getData=()=>{
        setProgress(true)
        API.get(`/v1/contact_suggestion`).then(response => {
            setUsers(response.data.data)
            setProgress(false)
        }).catch((error) => {
            setProgress(true)
        });
    }

    return (
        <>
            <h4>Contact Suggestions</h4>
            <div className="card shadow-sm">
                <div className="Account">
                    <ul className="list-group list-group-flush">
                    {progress && demo_data?.map((post, i) => (
                        <li key={i} className="list-group-item widget-item-list ">
                        <PlaceholderSuggestFriend  key={i} backgroundColor="#ffffff"
                                      foregroundColor="#ecebeb" />
                        </li>
                    ))}
                    </ul>
                    <ul className="list-group list-group-flush">
                        {
                        !progress && users?.map((value, index) => (
                        <li key={index} className="list-group-item widget-item-list ">
                            <div className="following-item d-flex justify-content-between align-items-center">
                                <a href={`/contact/${value.id}`}><div className="user-picture-container rounded-circle" style={{backgroundImage: `url(${value.avatar})`}} /></a>
                                <span className="name"><a href={`/contact/${value.id}`}>{value.name} </a></span>
                                <span className="add-friend"><a className={'clickable'} onClick={()=>{ _sendFriendRequest(value.id) }}> {isLoading && div_id===value.id ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-5"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                ) : (
                                    <i className="fa-solid fa-circle-plus" />
                                )}</a></span>
                            </div>
                        </li>
                        ))
                        }




                    </ul>
                </div>
            </div>
            </>
    )
}