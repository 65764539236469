import { useState } from "react";
import { useEffect } from "react";
import { memo } from "react";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";
import API from "../../Network/API";
import AddGroupModal from "../Modal/AddGroupModal";

const NewChatList = (props) => {
    const [chatlist_data, setChatListData] = useState([]);
    const [progress, setProgress] = useState(false);
    const [modalDialog, setModalDialog] = useState(false);
    const [message, setMessage] = useState(false);

    const toggleModal = () => {
        setModalDialog(!modalDialog)
    };
    const onClick = () => {
        // fileInput.current.click();
        toggleModal();
     }
    useEffect(()=>{
        API.get('search_friend').then((response) => {
            if (response.data.success === true) {
                setProgress(false)
                setChatListData(response.data.data)
            } else {
                setProgress(false)
            }
        }).catch((error) => {
            setProgress(false)
        });
    },[])

    const fetch_search = (value) => {
        setProgress(true)
        if (value === "" || value === null) {
            setProgress(false)
            return false
        } else {
            API.get('search_friend?q=' + value).then((response) => {
                if (response.data.success === true) {
                    setProgress(false)
                    setChatListData(response.data.data)
                } else {
                    setProgress(false)
                }
            }).catch((error) => {
                setProgress(false)
            });
        }

    }

    const callbackData=(value)=>{
        if(value==true){
            props.handleShowB()
        }
    }

    return (
        <>
            <div className="all-chater-list bg-white margin-top-minex group-all-chater" id="group-all-chater" style={{ display: 'block' }}>
                <div className="msg-search-heading d-flex align-items-center mb-3 mt-3">
                    <div className="group-back-btn">
                        <a href='#' className="me-3 text-dark" id="group-back-btn-hide" onClick={props.handleShowB}> <i className="fa-solid fa-angle-left" /> </a>
                    </div>
                    <h6 className="mb-0">New Message</h6>
                </div>
                <div className="message-people-srch">
                    <div className="messages-search-box">
                        <form>
                            <input type="text" onChange={(e) => fetch_search(e.target.value)} className="input-search" placeholder="Search contacts..." />
                            <button type="button">
                                <i className="fa-solid fa-magnifying-glass" />
                            </button>
                        </form>
                    </div>
                </div>
                <div className="mesg-peple">
                    <ul className="messages-list">
                        <li className>
                            <a href="javascript:void(0)" className="group-create-btn d-flex align-items-center" onClick={onClick}>
                                <div className="group-create-btn-icon text-white bg-primary me-3">
                                    <i className="fa-solid fa-plus" />
                                </div>
                                <p className="m-0 fw-bold text-dark"> Create New Group</p>
                            </a>
                        </li>

                        {progress && [1, 2, 3, 4, 5]?.map((post, index) => (
                            <div key={index}>
                                <li>

                                    <div className="usr-msg-details d-flex align-items-center">
                                        <div className="usr-ms-img flex-grow-1">
                                            <ContentLoader
                                                speed={3}
                                                width={250}
                                                height={80}
                                                viewBox="0 0 250 80"
                                                backgroundColor="#dfdddd"
                                                foregroundColor="#ebebeb"

                                            >
                                                <circle cx="53" cy="57" r="24" className="user-picture-container rounded-circle" />
                                                <rect x="81" y="43" rx="0" ry="0" width="327" height="8" />
                                                <rect x="82" y="57" rx="0" ry="0" width="271" height="7" />
                                            </ContentLoader>

                                        </div>
                                    </div>
                                </li>
                            </div>
                        ))}
                        {!progress && chatlist_data?.length > 0 ?
                            chatlist_data?.map((value, index) => (
                                <li className>
                                    <div className="usr-msg-details d-flex align-items-center">
                                        <div className="usr-ms-img flex-grow-1">
                                            <Link to={`/messages/thread/${value.user_id}`} onClick={props.handleShowB} className="d-flex align-items-center">
                                                <div className="user-picture-container rounded-circle" style={{background:`url(${value.avatar})`}} />
                                                <div className="usr-mg-info">
                                                    <h3>{value.name} {value.is_online == 1 ? <span className="ms-1 people-active "><i class="fa-solid fa-circle"></i></span> : <span className="ms-1 people-active people-inactive"><i class="fa-solid fa-circle"></i></span>} </h3>
                                                    <p className>{value.bio?.length > 23 ? `${value.bio.substring(0, 22)}...` : value.bio}</p>
                                                </div>
                                            </Link>
                                        </div>
                
                                    </div>
                                </li>
                            )): ''}


                    </ul>
                </div>
            </div>
            <AddGroupModal isModalVisible={modalDialog} handleShowB={() => setModalDialog(false)} callbackData={callbackData}/>
        </>
    );
};

export default memo(NewChatList);