import React, { useState, useCallback, useRef } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Progress,
    ModalFooter,
} from "reactstrap";

import { toast } from "react-toastify";
import API from "../../Network/API";
import { useDispatch } from "react-redux";
import {ADD_POST_FETCH_SUCCESS} from "../../Reducer/postReducer";

export default function RealsCreateModal(props) {
    const fileObj = [];
    const fileArrayAnotherImage = [];
    const fileArrayAnother = [];
    const fileArrayVideo = [];
    const fileArray = [];
    const [isLoading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [files, setFile] = useState([]);
    const [fileArrayImage, setFileArray] = useState([]);
    const [post_modalDialog, setPostShareModalDialog] = useState(false);
    const [video_url, setVideoUrl] = useState('');
    const fileInput = React.useRef();
    const dispatch = useDispatch();

    const myArr = JSON.parse(localStorage.getItem('user_data'));
    let msgText = "What's on your mind? " + localStorage.getItem("profile_name") ?? "";

    const removeImage = (url, indexNumber) => {
        const index = files.indexOf(url);
        if (index > -1) { // only splice array when item is found
            files.splice(index, 1); // 2nd parameter means remove one item only
        }
        setFile([...files, files])
        removeElement(indexNumber)

    }

    const removeElement = (index) => {
        const filesf = files.filter((_, i) => i !== index);
        const filesOrginal = fileArrayImage.filter((_, i) => i !== index);
        setFile(filesf); // this array reset for display instant image showing
        setFileArray(filesOrginal); // this array reset for upload to server
    };

    const uploadHandlerSecondInput = (e) => {
        setSelectedFile(e.target.files[0]);
        setFileUrl(URL.createObjectURL(e.target.files[0]))
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            let extn = e.target.files[i].name.split('.').pop();

            if (extn.toLowerCase() === 'mp4' || extn.toLowerCase() === 'wmv' || extn.toLowerCase() === 'avi' || extn.toUpperCase() === "MKV" || extn.toUpperCase() === "WEBM" || extn.toUpperCase() === "MOV") {
                fileArrayVideo.push(URL.createObjectURL(fileObj[0][i]));

            } else {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
            }


            if (fileArrayImage.indexOf(fileObj[0][i]) === -1) {
                fileArrayAnother.push(fileObj[0][i])
            }

            if (files.indexOf(URL.createObjectURL(fileObj[0][i])) === -1) {
                fileArrayAnotherImage.push(URL.createObjectURL(fileObj[0][i]))
            }




        }

        setFileArray([...fileArrayImage, ...fileArrayAnother]) // this is for upload to server
        setFile([...files, ...fileArrayAnotherImage]) // this is for display

    }

    const uploadHandler = (e) => {
        setSelectedFile(e.target.files[0]);
        setFileUrl(URL.createObjectURL(e.target.files[0]))
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            let extn = e.target.files[i].name.split('.').pop();

            if (extn.toLowerCase() === 'mp4' || extn.toLowerCase() === 'wmv' || extn.toLowerCase() === 'avi' || extn.toUpperCase() === "MKV" || extn.toUpperCase() === "WEBM" || extn.toUpperCase() === "MOV") {
                fileArrayVideo.push(URL.createObjectURL(fileObj[0][i]));
            } else {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
            }
            fileArrayAnother.push(fileObj[0][i])
            fileArrayAnotherImage.push(URL.createObjectURL(fileObj[0][i]))
        }
        setFileArray(fileArrayAnother) // this is for upload to server
        setFile(fileArrayAnotherImage) // this is for display
        // console.log(fileArrayAnother);
    }


    function onCreatePost() {
        if (selectedFile === null && message === null) {
            toast.error("Post is empty");
            return false;
        }
        const fd = new FormData();
        if (selectedFile != null) {
            let fileExtention = selectedFile.name.split('.').pop();
            if (fileExtention.toUpperCase() == "MP4" || fileExtention.toUpperCase() == "WMV" || fileExtention.toUpperCase() == "AVI" || fileExtention.toUpperCase() == "MKV" || fileExtention.toUpperCase() == "WEBM" || fileExtention.toUpperCase() == "MOV") {
                if (selectedFile) {
                    fd.append('video[]', selectedFile, selectedFile.name);
                }
            }
        }

        if (message != "") {
            fd.append('details', message);
        }
        
        fd.append('is_play', 1);
        setLoading(true)
        API.post("/v1/posts", fd, {
            onUploadProgress: (progressEvent) => {
                const progressPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(progressPercentage);
            }
        })
            .then((response) => {
                setLoading(false)
                if (response.data.success === true) {
                    toast.success('Short video published successfully');
                    // console.log(response.data)
                     dispatch(ADD_POST_FETCH_SUCCESS(response.data.data))
                     setFile([])
                     setFileArray([])
                     localStorage.setItem('message','')
                     props.handleShowB()

                    // window.location = "/";
                } else if (response.data.success === false) {
                    //console.log(response.data.message);
                }
            })
            .catch((err) => {
                setLoading(false)
            });

    }

const inputValue=(e)=>{
    setMessage(e.target.value)
    localStorage.setItem('message',e.target.value)
}

const handlePaste = (e) => {
    if (e.clipboardData.files.length) {
        const fileObject = e.clipboardData.files[0];
        setSelectedFile(fileObject);
        setFileUrl(URL.createObjectURL(fileObject))
        fileArray.push(URL.createObjectURL(e.clipboardData.files[0]))
        fileArrayAnother.push(e.clipboardData.files[0])
        fileArrayAnotherImage.push(URL.createObjectURL(e.clipboardData.files[0]))
        setFileArray([...fileArrayImage,e.clipboardData.files[0]])
        setFile([...files,URL.createObjectURL(fileObject)]) // this is for display
         
    }
}


    const dropZoneRef = useRef(null);
  
    const handleDrop = (e) => {
      e.preventDefault();
      const fileObject = e.dataTransfer.files[0];
  
      setSelectedFile(fileObject);
        setFileUrl(URL.createObjectURL(fileObject))
        fileArray.push(URL.createObjectURL(fileObject))
        fileArrayAnother.push(fileObject)
        fileArrayAnotherImage.push(URL.createObjectURL(fileObject))
        setFileArray([...fileArrayImage,fileObject])
        setFile([...files,URL.createObjectURL(fileObject)]) // this is for display
    //   // Create a new FileReader to read the contents of the dropped image file
    //   const reader = new FileReader();
  
    //   reader.onload = (event) => {
    //     const imageUrl = event.target.result;
    //     const clipboardItem = new ClipboardItem({ 'image/png': imageFile });
    //     navigator.clipboard.write([clipboardItem])
    //       .then(() => console.log('Image copied to clipboard'))
    //       .catch((error) => console.error('Could not copy image to clipboard: ', error));
    //   };
  
    //   reader.readAsDataURL(imageFile);
    };
  
    const handleDragOver = (e) => {
      e.preventDefault();
    };

    return (<>
        <Modal
            isOpen={props.isModalVisible}
            size=""
            toggle={props.handleShowB}
            className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
        >
            <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                Create short videos
            </ModalHeader>
            <ModalBody>
                <div className="share-creation-state">
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="image">
                            <a href="javascript:void(0)">
                                <div className="user-picture-container rounded-circle"
                                    style={{ background: `url(${myArr.avatar})` }} />
                            </a>
                        </div>
                        <div className="info ms-2">
                            <span className="name">
                                <a href="javascript:void(0)" className="text-dark">{myArr.name}</a>
                            </span>
                            <div className="dropdown">
                                {/* <button
                                    className="btn btn-share_post_privacy dropdown-toggle rounded-pill border border-dark"
                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <i className="fa-solid fa-globe me-1" /> Everyone
                                </button> */}
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="javascript:void(0)">Public</a></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="share_post_write_text" >
                    <form ref={dropZoneRef}
      onDrop={handleDrop}
      onDragOver={handleDragOver} onPaste={(e)=>handlePaste(e)}>
                        <div className="form-group">
                            <textarea name="message" className="form-control write_textarea"
                                placeholder={msgText} defaultValue={localStorage.getItem('message')} spellCheck="false" onChange={(e) => inputValue(e)} />
                        </div>
                    </form>
              
                </div>

                {progress > 0 && progress < 100  ?
                <Progress value={progress}>{progress}%</Progress>
                : progress == 100 ?
                <Progress color='success' value={progress}>Processing...</Progress>
                : ''
                }

                <div className="addPhotosVideo_content">
                

                    {files.length <= 0 ?
                        <div className="position-relative">

                            <div className="Add_PhotosVideo_btn position-relative">
                                <input className="PhotosVideo-choose-file" type="file" onChange={uploadHandler}
                                    multiple={true} accept="video/*" />
                                <div className="Add_PhotosVideo-body">
                                    <span className="Add_PhotosVideo-icon"><i
                                        className="fas fa-photo-video me-1" /></span>
                                    <h6>Add Videos</h6>
                                    <p>or drag and drop</p>
                                </div>
                            </div>

                            <div className="photosVideo_close_btn position-absolute">
                                <i className="fas fa-times-circle" />
                            </div>
                        </div>

                        :
                        (fileArrayImage || []).map((url, index) => (


                            <div className="position-relative" key={index} id={window.URL.createObjectURL(url)}>
                                
                                {url.type == 'image/png' || url.type == 'image/jpeg' || url.type=='image/gif'?
                                    <div className="post-image">
                                        <img className="img-fluid" key={index} src={window.URL.createObjectURL(url)} alt="image"
                                            style={{ height: '100%', width: '100%', padding: "10px",objectFit:'fill' }} />

                                    </div>
                                    : url.type == 'video/mp4' ?
                                    <div class="post-video">
                                        <a  class="post-video-btn mb-2">
                                            <video class="w-100 h-100">
                                                <source src={window.URL.createObjectURL(url)} type="video/mp4" />
                                            </video>
                                        </a>

                                    </div>
                                    :
                                    ''
                                }
                                <div className="photoAddButton position-absolute ">
                                    {index === 0 ?
                                        <div className="Add_Photos_btn">
                                            <input className="choose-file" type="file" onChange={uploadHandlerSecondInput}
                                                multiple={true} accept="video/*" />
                                            <span>
                                                <i className="fas fa-photo-video me-1" />
                                                Add Video
                                            </span>
                                        </div>
                                        : ''}

                                </div>
                                <div className="photos_close_btn position-absolute ">
                                    <i className="fas fa-times-circle text-dark"
                                        onClick={() => removeImage(window.URL.createObjectURL(url), index)} />
                                </div>

                            </div>

                        ))

                    }
                

                </div>

            </ModalBody>
            <ModalFooter className={'post-footer-comment-icon'}>
                <button className="btn btn-primary rounded-1" onClick={onCreatePost}>Publish {isLoading ? (
                    <span
                        className="spinner-border spinner-border-sm ml-5"
                        role="status"
                        aria-hidden="true"
                    ></span>
                ) : (
                    <span></span>
                )}</button>

            </ModalFooter>
        </Modal>
    </>)
}