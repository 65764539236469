
export default function Helps(props) {

    return(
        <>
            <div className="row d-flex justify-content-center">
                <div className="col-md-10">
            <div className="friend_list-page">
            
                <div className="row g-2">
                    <div className="col-md-12">
                        <div className="news-feed news-feed-post bg-white shadow-sm mb-3 ">
                            <div className="post-body">
                                <h1>Connects Helps</h1>
                                <h2>Connects: Your All-in-One Communication & Collaboration Hub</h2>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What is Connects App?
                                        </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        <p>Connects App is an innovative platform empowering seamless real-time connections through integrated technologies. Ditch the app juggling and experience effortless communication, collaboration, and content sharing &ndash; all in one place.</p>
                                        <p>Features:</p>
                                        <ol>
                                        <li><strong>Connects Social</strong>: Stay close to loved ones. Share memories (photos, videos, text) across mobile, tablet, and PC instantly. Create groups, plan reunions, and relive moments seamlessly.</li>
                                        <li><strong>Connects Video Meetings:</strong> Boost productivity and save time. Host high-quality video conferences with colleagues, friends, or clients &ndash; all within Connects. Enjoy screen sharing, recording, and breakout rooms for enhanced collaboration.</li>
                                        <li><strong>Connects Messages:</strong> Unify your communication. Securely chat, send photos, and stay connected with your network &ndash; all on one platform. Forget switching apps; simplify your communication flow with Connects.</li>
                                        <li><strong>Connects Video Sharing: </strong>Streamline your video experience. Capture, share, and manage all your videos &ndash; personal memories, educational content, marketing campaigns &ndash; effortlessly within Connects. No more platform hopping, just seamless video solutions.</li>
                                        </ol>
                                        <p>&nbsp;</p>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Benefits of Connects App
                                        </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        <ol>
                                        <li>Enhanced Connection: Stay closer to loved ones and colleagues, regardless of distance.</li>
                                        <li>Increased Productivity: Save time and work efficiently with integrated video meetings and collaboration tools.</li>
                                        <li>Simplified Communication: Ditch the app clutter and manage all your communication needs through Connects.</li>
                                        <li>Streamlined Content Sharing: Capture, share, and manage videos easily, without platform jumping.</li>
                                        </ol>
                                        <p>Start Connecting Today!</p>
                                        <p>Experience the future of communication and collaboration. Sign up for Connects and unlock a world of seamless connections, boundless productivity, and effortless content sharing.</p>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        How to start video conferencing with Connects?
                                        </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        <h1>It's easy!</h1>
                                        <ol>
                                        <li>Head to our website: <a href="https://connectsapp.com/">https://connectsapp.com/</a> and create your free account.</li>
                                        <li>Download the mobile app: If you prefer mobile meetings, download the Connects App for iOS or Android.</li>
                                        <div className="col-sm-12 p-0 mt-4">
                                            <div className="mobile-space-between">
                                            <a target="_blank" href="https://apps.apple.com/gb/app/connects-social-and-meeting/id1567949697" className="android">
                                                <img width={128} src="https://b.connectsweb.com/public/img/app-store-badge.png"/>
                                            </a>
                                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.tryonex.connects" className="android">
                                                <img width={160} src="https://b.connectsweb.com/public/img/google-play-badge.png"/>
                                            </a>
                                        </div>
                                        </div>
                                        <li>Log in and connect: Once logged in, explore the platform and discover all the features for seamless video conferencing.</li>
                                        </ol>
                                        <p>Here's what you can do with Connects video conferencing:</p>
                                        <ol>
                                        <li>Schedule meetings: Set up advance calls or instant one-on-one sessions.</li>
                                        <li>Invite participants: Easily share meeting links with colleagues, friends, or clients.</li>
                                        <li>Join with ease: Click a link or launch the app, and you're in! No complicated setups required.</li>
                                        <li>Collaborate effectively: Share screens, use virtual whiteboards, and take notes together effortlessly.</li>
                                        <li>Connect on any device: Enjoy crystal-clear video and audio on your desktop, laptop, or mobile device.</li>
                                        </ol>
                                        <p>Start your free account today and experience the future of video conferencing with Connects!</p>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingFour">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Is Connects Free?
                                        </button>
                                        </h2>
                                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        <h1>Absolutely!</h1>
                                        <p>Connects is committed to providing free access to its core features, ensuring seamless communication and collaboration for everyone. To keep our platform running smoothly and fund ongoing development, we display a limited number of Google ads within the app.</p>
                                        <h2>Why Google Ads?</h2>
                                        <ol>
                                        <li>Minimal disruption: We carefully select and place ads to minimize any impact on your user experience.</li>
                                        <li>Personalized relevance: Google's advanced algorithms ensure ads are family friendly, making them more useful and engaging.</li>
                                        <li>Supporting a free platform: By viewing these ads, you directly contribute to keeping Connects free for everyone, fostering a more connected community.</li>
                                        </ol>
                                        <h2>What You Get for Free:</h2>
                                        <p>Even with the presence of ads, you'll enjoy a comprehensive suite of all features at no cost, including:</p>
                                        <ol>
                                        <li>Unlimited chats and messages</li>
                                        <li>Advance video meetings with friends and colleagues</li>
                                        <li>Secure video sharing and storage, and chat</li>
                                        <li>And much more!</li>
                                        </ol>
                                        <p>Thank you for understanding!</p>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                            
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
                </div>
                </div>
                </>
    )
}