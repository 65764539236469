
import { createSlice } from '@reduxjs/toolkit';

export const MyProfileSlice = createSlice({
    name: 'MyProfileSlice',
    initialState: {
        loading: true,
        data: {},
        personal_info: {},
        post_images:[],
        error: false,
    },
    reducers: {
        MY_PROFILE_FETCH_START: (state) => {
            state.loading = false
        },
        MY_PROFILE_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.personal_info = action.payload.personal_info
            state.post_images = action.payload.post_images
        },
        
        MY_PROFILE_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { MY_PROFILE_FETCH_START,MY_PROFILE_FETCH_SUCCESS,MY_PROFILE_FETCH_ERROR } = MyProfileSlice.actions;
export default MyProfileSlice.reducer;