import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import PostLoader from '../assets/img/loader.gif';
import API from "../Network/API";
import { SUGGESTED_VIDOE_DELETE_COMMENT, SUGGESTED_VIDOE_POST_FETCH_COMMENT_ADDON, SUGGESTED_VIDOE_POST_FETCH_SUCCESS_LOAD, SUGGESTED_VIDOE_POST_FETCH_SUCCESS_LOAD2 } from "../Reducer/suggestedVideoReducer";
import { SINGLE_DELETE_COMMENT, SINGLE_POST_FETCH_COMMENT_ADDON } from "../Reducer/singlePostReducer";
import { toast } from "react-toastify";
import { FPOST_FETCH_COMMENT_ADDON } from "../Reducer/friendProfileReducer";


export default function SingleRealVideo() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const selector_video = useSelector((state) => state.suggestedVideoReducer)
  const post_data = selector_video?.post_data;
  const [isLoading, setLoading] = useState(false);
  const [isVideoLoading, setVideoLoading] = useState(true);
  const [currentPost, setCurrentPost] = useState(null);
  const [current_index, setCurrentIndex] = useState(0);
  const [prevPostId, setPrevPostId] = useState(null);
  const [nextPostId, setNextPostId] = useState(null);
  const [page, setPage] = useState(1);
  const [love_react, setLoveReact] = useState(false);
  const [comment_love_react, setCommentLoveReact] = useState(false);
  const [reply_comment_delete, setReplyCommentDelete] = useState(false);
  const [reply_comment_id, setReplyCommentID] = useState(false);
  const [comment_love_react_add, setCommentLoveReactAdd] = useState(0);

  const [write_comment, setWriteComment] = useState(null);
  const [reply_comment, setReplyComment] = useState(null);

  const [p_id_like, setPIDLike] = useState(0);
  const [p_id_com, setPIDCom] = useState(0);
  const [set_like, setLike] = useState(0);
  const [last_comments, setLastComments] = useState([]);
  const [set_comment, setComment] = useState(0);

  const [postId, setPostId] = useState(0);
  const [post_modalDialog, setPostShareModalDialog] = useState(false);
  const [show, setShow] = useState(false);
  const [show_comment_box, setShowCommentBox] = useState(false);
  const [is_comment_loading, setCommentLoading] = useState(false);
  const [reply_com_id, setReplyComID] = useState(0);
  const [comment_id, setCommentID] = useState(0);
  const [is_com_delete, setISComDelete] = useState(false);
  const [comment_page, setCommentPage] = useState(2);
  const [play_video, setPlayVideo] = useState(false);
  const [mute_sound, setMuteSound] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);
  const [next_page_url, setNextPageURL] = useState(null);
  
    useEffect(() => {

     fetchCurrentPost(id);
  }, [id, post_data]);



  useEffect(() => {
    console.log("ddd")
    API.get(`/get_post_comments/${id}`).then(response => {
          setVideoLoading(false)
            dispatch(SUGGESTED_VIDOE_POST_FETCH_SUCCESS_LOAD2([response.data.data]))
          console.log(selector_video?.post_data);
          //  console.log([response.data.data]);
      }).catch((error) => {
        setVideoLoading(false)
      });

  }, [id]);

  

  const fetchCurrentPost = (posId) => {
    const post = post_data?.find((post_dd) => post_dd.id === parseInt(posId, 10));

    setLastComments(post?.last_comments)
    if (post?.last_comments?.length > 10) {
      setNextPageURL('/post_comment_history?page=2');
    }
    setCurrentPost(post);
  };

  const handlePrevClick = () => {
    if (current_index < 0) {
      return;
    }
    const prevIndex = (current_index - 1 + post_data.length) % post_data.length;
    setCurrentIndex(current_index - 1);
    setPrevPostId(post_data[prevIndex].id);
    fetchCurrentPost(post_data[prevIndex].id);
    setIsPlaying(true);
  };

  const handleNextClick = () => {

    if (post_data.length < current_index) {
      return;
    }
    const nextIndex = (current_index + 1) % post_data.length;
    setCurrentIndex(current_index + 1);
    setNextPostId(post_data[nextIndex].id);
    fetchCurrentPost(post_data[nextIndex].id);
    setIsPlaying(true);
    const lastPageIndex = post_data.length - 1;

    if (lastPageIndex >= 0 && current_index == lastPageIndex) {
      setPage(page + 1)
    }

  };

  const postLike = (post_id) => {
    setLoading(true)
    setLoveReact(true)
    setLike(1)
    setPIDLike(post_id)
    API.post("/post_likes", {
      post_id: post_id,
      react_icon: 1,
    }).then((response) => {
      setLoading(false)
      setLoveReact(true)
      if (response.data.success === true) {
        // toast.success('Post loved Successfully');
      } else {
        setLoveReact(false)
      }
    });
  }

  const submit_comment = (p_id) => {
    if (write_comment === null) {
      toast.error('Enter something!');
      return false;
    }
    setLoading(true)
    setComment(1)
    API.post("/post_comments", {
      post_id: p_id,
      comment: write_comment,
    }).then((response) => {
      setLoading(false)
      setWriteComment("")
      if (response.data.success === true) {
        dispatch(SUGGESTED_VIDOE_POST_FETCH_COMMENT_ADDON(response.data.data))
        dispatch(FPOST_FETCH_COMMENT_ADDON(response.data.data))
        dispatch(SINGLE_POST_FETCH_COMMENT_ADDON(response.data.data))
        setPostId(p_id)
        toast.success('comment success');
      }
    });

  }

  const toggleReply = (com_id) => {
    setShow(!show)
    setPIDCom(com_id)
  };

  const toggleComment = (post_id) => {
    setShowCommentBox(!show_comment_box)
    setPostId(post_id)
  };


  const commentLike = (post_id, comment_id) => {
    setLoading(true)
    setCommentLoveReact(true)
    setPIDCom(comment_id)
    setCommentLoveReactAdd(1)
    API.post("/comment_like", {
      post_id: post_id,
      comment_id: comment_id,
      react_icon: 1,
    }).then((response) => {
      setLoading(false)
      if (response.data.success === true) {
        // toast.success('Comment loved Successfully');
      }
    });
  }

  const commentsReplyDelete = (comments_id) => {
    setLoading(true)
    API.delete("/reply_comment_delete/" + comments_id).then((response) => {
      if (response.data.success === true) {
        setLoading(true)
        setReplyCommentDelete(true)
        setReplyCommentID(comments_id)
        toast.success('Comment deleted');
      }
    });
  }

  const handleReplyMessage = (p_id, cm_id) => {
    setLoading(true)
    setReplyComID(cm_id)
    API.post("/comment_reply", {
      post_id: p_id,
      comment_id: cm_id,
      comment: reply_comment,
    }).then((response) => {
      setLoading(false)
      setReplyComment('')
      if (response.data.success === true) {

        // toast.success('Reply sent');
      }
    });
  }

  const commentsDelete = (comments_id, post_id) => {
    setLoading(true)
    API.delete("/comment_delete/" + comments_id).then((response) => {
      setLoading(false)
      if (response.data.success === true) {
        setISComDelete(true)
        setCommentID(comments_id)
        setPostId(post_id)
        setComment(-1)
        const comPayLod = {
          comment_id: comments_id,
          post_id: post_id,
        }
        dispatch(SUGGESTED_VIDOE_DELETE_COMMENT(comPayLod))
        dispatch(SINGLE_DELETE_COMMENT(comPayLod))
        // toast.success('Comment deleted');
      }
    });
  }

  function fetchPostCommentNext(postId) {

    setCommentLoading(true)

    API.get('post_comment_history/?page=' + comment_page + '&post=' + postId, {
      'offset': last_comments.length
    }).then((response) => {
      setCommentLoading(false)
      const paginator = response.data.data;
      if (paginator.get_posts.length) {
        setLastComments([...last_comments, ...paginator.get_posts])
        setNextPageURL(paginator.next_page_url)
        setCommentPage((comment_page + 1))
      }
    }).catch((error) => {
      setCommentLoading(false)
    });

  }

  const playVideo = () => {
    videoRef.current.play();
    setIsPlaying(true);
  };

  const pauseVideo = () => {
    videoRef.current.pause();
    setIsPlaying(false);
  };

  const muteVideo = () => {
    if (videoRef.current) {
      videoRef.current.muted  = true;
      setMuteSound(true);
    }
  };

  const soundVideo = () => {
    if (videoRef.current) {
      videoRef.current.muted = false;
      setMuteSound(false);
    }
  };



  const handleVideoEnded = () => {
    setIsPlaying(false);
  };

  const toggleModalSharePost = () => {
    setPostShareModalDialog(!post_modalDialog)
  };
  const share_post = (p_id) => {
    setPostId(p_id);
    toggleModalSharePost()
  }

  return (
    <>
      {selector_video?.loading || isVideoLoading ?
        <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center' }}>
          <img src={PostLoader} alt='Post loader' height={80} width={80} />
        </div>
        :
        <section className="reels-area">
          <div className="reels-area-left">
            <div className="reelsVideoBlurBackground">
              <span className="reelsVideoBlurBackground-wrap">
                <img src={`${currentPost?.videos?.[0]?.thumbnail}`} alt="" className="reelsVideoBlurBackground-img" />
              </span>
            </div>
            <div className="reelsVideoWrapper">
              <div className="reelsVideoWrapperContainer">
                <div className="reelsBasicPlayerWrapper">
                  <video src={currentPost?.videos?.[0]?.video_stream} ref={videoRef} onEnded={handleVideoEnded} autoPlay />
                </div>
              </div>
            </div>
            <Link to={`/`} className="btn reels-area-close-btn"><i class="fa-solid fa-xmark" /></Link>

            <button className="btn arrow-left-previous-video" onClick={handlePrevClick}>
              <i className="fa-solid fa-angle-up" />
            </button>

            <button className="btn arrow-left-next-video" onClick={handleNextClick}>
              <i className="fa-solid fa-angle-up" />
            </button>
            <div className="reels-sound-area reels-sound-push">
              <div className="reels-sound-push-area">
                {isPlaying ?
                  <i className="fa-solid fa-pause" onClick={pauseVideo} />
                  :
                  <i className="fa-solid fa-play" onClick={playVideo} />
                }
              </div>
            </div>
            <div className="reels-sound-area reels-sound-volume">
              <button className="btn reels-sound-volume-area reels-sound-push-area">
                {mute_sound?
                <i className="fas fa-volume-mute" onClick={soundVideo}/>
               
                :
                <i className="fas fa-volume-up" onClick={muteVideo}/>
                
}
              </button>
            </div>
          </div>
          <div className="reels-area-right">
            <div className="reelsCreatorArea">
              <div className="reelsCommentListContainer reelsCommentListContainer-pad-24">
                <div className="reelsProfileWrapper">
                  <div className="reelsDescriptionContentWrapper">
                    <div className="reelsUserInfoContainer">
                      <div className="news-feed-area">
                        <div className="news-feed news-feed-post m-0 p-0">
                          <div className="post-header d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                              <div className="image">
                                <a href={"/contact/" + currentPost?.user_id}><div className="user-picture-container rounded-circle" style={{ background: `url(${currentPost?.avatar})` }} /></a>
                              </div>
                              <div className="info ms-3">
                                <span className="name">
                                  <a href={"/contact/" + currentPost?.user_id}>{currentPost?.post_by}
                                    {currentPost?.is_online == 1 ?
                                      <i className="fa-solid fa-circle" />
                                      : <i className="fa-solid fa-circle text-dark" />}
                                  </a>
                                </span>
                                <span className="small-text"><a href="javascript:void(0)">{currentPost?.post_time}</a></span>
                              </div>
                            </div>
                          </div>
                          <div className="reelsBody">
                            <h6 className="reel-video-title">
                              {currentPost?.details}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="reelsButtonWrapper">
                    <div className="reelsButtonWrapper-padding">
                      <button type="button" className="reelsButtonWrapper-btn">
                        <span className="reelsButtonWrapper-btn-icon">
                          {currentPost?.is_like ?
                            <i className="fa-solid fa-heart i-loved" />
                            :
                            <>
                              <i onClick={() => postLike(currentPost?.id)} className={`fa-solid fa-heart clickable ${love_react && p_id_like === currentPost?.id ? 'i-loved' : ''}`} />
                            </>
                          }
                        </span>
                        <strong className="reelsButtonWrapper-btn-StrongText">{currentPost?.totalLikes + set_like}</strong>
                      </button>

                      <button type="button" className="reelsButtonWrapper-btn ms-3">
                        <span className="reelsButtonWrapper-btn-icon">
                          <i className="fa-solid fa-comment" />
                        </span>
                        <strong className="reelsButtonWrapper-btn-StrongText">{currentPost?.totalComments + set_comment}</strong>
                      </button>

                      <button type="button" className="reelsButtonWrapper-btn ms-3" onClick={() => share_post(currentPost?.id)}>
                        <span className="reelsButtonWrapper-btn-icon">
                          <i className="fa-solid fa-share-nodes" />
                        </span>
                        <strong className="reelsButtonWrapper-btn-StrongText">0</strong>
                      </button>

                    </div>
                  </div>
                  <div className="reelsCommentsArea">
                    <div className="reelsCommentsAreaHeading Promoted_Post_content">
                      <h6>Comments</h6>
                    </div>
                    <div className="reelsCommentsAreaWrapp mt-3">
                      <div className="news-feed-area">
                        <div className="news-feed news-feed-post m-0 p-0">
                          <div className="post-body">
                            {last_comments?.length > 0 ?

                              <>
                                <div style={{ height: '10px' }}></div>
                                {last_comments?.slice(0, 10)?.map((getcomment, i) => (
                                  <>
                                    <div key={i}
                                      className={`post-comment-list ${is_com_delete && comment_id === getcomment.id ? 'd-none' : ''} `}
                                      id={"comments_" + getcomment.post_id}>
                                      <div className="comment-list d-flex justify-content-between">
                                        <div className="comment-image">
                                          <Link to={`/contact/${getcomment.comment_user_id}`}>
                                            <div className="user-picture-container rounded-circle"
                                              style={{ backgroundImage: `url(${getcomment.avatar})` }} />
                                          </Link>
                                        </div>
                                        <div className="comment-list-meta">
                                          <div className="comment-info shadow-sm">
                                            <div className="d-flex justify-content-between">
                                              <div className="name">
                                                <Link to={`/contact/${getcomment.comment_user_id}`}>{getcomment.comment_by}</Link>
                                                {getcomment.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                              </div>
                                              <a className="time">{getcomment.comment_time}</a>
                                            </div>
                                            <p>{getcomment.comment}</p>
                                          </div>
                                          <ul className="comment-react">
                                            <li className="post-react">
                                              {getcomment.is_like ?
                                                <a className={'clickable'}><i
                                                  className="fa-solid fa-heart" /><span>Love</span>
                                                  <span
                                                    className="number"> {getcomment.total_likes} </span></a>
                                                :
                                                <a className={'clickable'}
                                                  onClick={() => commentLike(currentPost?.id, getcomment.id)}><i
                                                    className={`fa-solid fa-heart ${p_id_com === getcomment.id && comment_love_react ? 'i-loved' : 'text-dark'} `} /><span>Love</span>
                                                  <span
                                                    className="number"> {p_id_com === getcomment.id && comment_love_react ? getcomment.total_likes + comment_love_react_add : getcomment.total_likes > 0 ? getcomment.total_likes : ''} </span></a>
                                              }

                                            </li>
                                            <li className="post-comment">
                                              <a className={'clickable'}
                                                onClick={() => toggleReply(getcomment.id)}><i
                                                  className="fa-solid fa-comment" />Reply{getcomment.comment_reply?.length > 0 ? `(${getcomment.comment_reply?.length})` : ''} <span
                                                    className="number"> </span></a>
                                            </li>
                                            <li className="post-comment-replay-icon">
                                              <a href="#0"><i className="fa-solid fa-angle-down" /></a>
                                            </li>
                                          </ul>
                                        </div>
                                        {getcomment.is_deletable === 1 ?
                                          <div className="comment-button">
                                            <div className="dropdown">
                                              <button className="dropdown-toggle" type="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false"><i
                                                  className="fa-solid fa-ellipsis-vertical" /></button>
                                              <ul className="dropdown-menu">

                                                <li><a className="dropdown-item d-flex align-items-center clickable"
                                                  onClick={() => commentsDelete(getcomment.id, currentPost?.id)}><i
                                                    className="fa-solid fa-trash-can" /> Delete </a></li>
                                              </ul>
                                            </div>
                                          </div>
                                          : ''}
                                      </div>
                                    </div>

                                    {getcomment.comment_reply?.length > 0 ?
                                      <>
                                        {getcomment.comment_reply?.map((reply, i) => (
                                          <>
                                            <div key={Math.random()} id={`reply${reply.id}`}
                                              className={`post-comment-list post-comment-replay ${p_id_com === getcomment.id && show ? '' : 'd-none'} ${reply_comment_delete && reply_comment_id === reply.id ? 'd-none' : ''}`}>
                                              <div className="comment-list d-flex justify-content-between">
                                                <div className="comment-image">
                                                  <Link to={`/contact/${reply.comment_user_id}`}>
                                                    <div
                                                      className="user-picture-container rounded-circle"
                                                      style={{ backgroundImage: `url(${reply.avatar})` }} />
                                                  </Link>
                                                </div>
                                                <div className="comment-list-meta">
                                                  <div className="comment-info shadow-sm">
                                                    <div className="d-flex justify-content-between">
                                                      <div className="name">
                                                        <Link
                                                          to={`/contact/${reply.comment_user_id}`}>{reply.comment_by}</Link>
                                                        {reply.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                                      </div>
                                                      <span className="time"
                                                      >{reply.comment_time}</span>
                                                    </div>
                                                    <p>{reply.comment}</p>
                                                  </div>
                                                </div>
                                                {reply.is_deletable ?
                                                  <div className="comment-button">
                                                    <div className="dropdown">
                                                      <button className="dropdown-toggle"
                                                        type="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <i
                                                          className="fa-solid fa-ellipsis-vertical" />
                                                      </button>
                                                      <ul className="dropdown-menu">

                                                        <li><span
                                                          onClick={() => commentsReplyDelete(reply.id)}
                                                          className="dropdown-item d-flex align-items-center clickable"
                                                        ><i
                                                            className="fa-solid fa-trash-can" /> Delete</span>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div> : ""
                                                }
                                              </div>
                                            </div>


                                          </>

                                        ))}


                                      </> : ""
                                    }
                                    <div
                                      className={`post-comment-list post-comment-replay  ${p_id_com === getcomment.id && show ? '' : 'd-none'}`}>
                                      <form className="post-footer d-flex justify-content-between">
                                        <div className="footer-image">
                                          <Link to="#0">
                                            <div
                                              className="user-picture-container rounded-circle"
                                              style={{ backgroundImage: `url(${localStorage.getItem("profile_img") ?? "https://via.placeholder.com/50x50.png"})` }} />
                                          </Link>
                                        </div>
                                        <div className="form-group post-footer-comment-form">
                                          <textarea name="message" className="form-control"
                                            placeholder="Write a comment..."
                                            defaultValue={'@' + getcomment.comment_by + ' '} value={reply_comment} onChange={(e) => setReplyComment(e.target.value)} />
                                          <label className="post-footer-comment-icon">
                                            {/* <a href="#0"><i
                                                                                    className="fa-regular fa-face-smile" /></a> */}
                                            <span className="horizontal-divided" />
                                            <button type="button" onClick={() => handleReplyMessage(currentPost?.id, getcomment.id)} className="post-footer-comment-submit">Post  {isLoading && reply_com_id === getcomment.id ? (
                                              <span
                                                className="spinner-border spinner-border-sm ml-5"
                                                role="status"
                                                aria-hidden="true"
                                              ></span>
                                            ) : (
                                              <span></span>
                                            )}
                                            </button>
                                          </label>
                                        </div>
                                      </form>
                                    </div>

                                  </>
                                ))}
                                {currentPost?.last_comments?.length > 10 && next_page_url != null ?
                                  <div className="view-comment-text text-center">
                                    <a className={'clickable'} onClick={() => fetchPostCommentNext(currentPost?.id)}>View more{is_comment_loading ? (
                                      <span
                                        className="spinner-border spinner-border-sm ml-5"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ) : (
                                      <span></span>
                                    )}</a>
                                  </div>
                                  : ''}

                              </>
                              : <a className={`clickable ${postId === currentPost?.id && show_comment_box ? 'd-none' : ''}`}
                                style={{ fontSize: '10px' }} onClick={() => toggleComment(currentPost.id)}>Be the first to comment
                                on this</a>
                            }


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="reelsBottomCommentContainer">
              <div className="reelsCommentBar">
                <div className="reel-replay position-relative">
                  <input value={write_comment} onChange={(e) => setWriteComment(e.target.value)} className="form-control reel-replay-input rounded-pill" type="text" placeholder="Write Comments..." aria-label="default input example" />
                  <i className="fa-solid fa-paper-plane send-icon" onClick={() => submit_comment(currentPost?.id)} />
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}