import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  timezone: "",
  datetime: "",
};

const TimeZoneSlice = createSlice({
  name: "timezone",
  initialState,
  reducers: {
    GET_TIMEZONE: (state, action) => {
      state.timezone = action.payload;
    },
    GET_DATETIME: (state, action) => {
      state.datetime = action.payload;
    },
  },
});

export const { GET_TIMEZONE,GET_DATETIME } = TimeZoneSlice.actions;
export default TimeZoneSlice.reducer;