import React, { useEffect, useState } from "react";
import API from "../../Network/API";
import ContentLoader from "react-content-loader"
import {useNavigate, BrowserRouter as Router, Redirect, Switch, Route, Routes, Link, useParams} from "react-router-dom";

export default function MeetingWaiting() {
  const [meetingData, setMeetingData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [demo_data, setDatas] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }]);
  const [progress, setProgress] = useState(false);
  let { room } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchRepositories()
  }, []);

  function fetchRepositories() {

    setProgress(true)

    API.get('/join/'+room).then((response) => {
        setMeetingData( response.data.all_data);
        setProgress(false)
    }).catch((error) => {
      setProgress(true)

    });

  }

  function checkMeeting(){
    API.get('/join/'+room).then((response) => {
      if (response.data.room!==""){
        window.location.href = "/meeting/"+room;
    }
    }).catch((error) => {
      // console.log(error)
    });
  }

  return (
    <>
    <div className="matting_waiting_page-bg">
    <div className="container">
        <div className="row">
        <div className="col-12">
            <div className="matting_waiting_page">
            <h4 className="meeting_waiting_heading">This meeting is scheduled.</h4>
            <div className="meeting_waiting_img-datetime">

                <div className="meeting_waiting_divider" />
                <div className="meeting_waiting_datetime">
                <h4>Start Date: {new Date(meetingData.start_date).toDateString()}</h4>
                <div className="meeting_waiting_time_zone">
                    <h6>Start Time: {new Date(meetingData.start_date).toLocaleString('en-US', { hour: 'numeric', minute:'numeric', hour12: true })}</h6>
                    <div className="meeting_waiting-time_zone-divider" />
                    <h6>Time Zone: {meetingData.time_zone}</h6>
                </div>
                </div>
                
            </div>
            <h6 className="meeting_waiting_heading">{meetingData.room_title}</h6>
            {meetingData.cover ? 
            <div className="meeting_waiting-cover-img">
                <img src={"https://b.connectsweb.com/storage/app/public/rooms/"+meetingData.cover} alt="" />
            </div>
            :
            ""
            }
            <p className="meeting_waiting_description">{meetingData.note}</p>
            <div className="meeting_waiting_refresh ">
                <button className="meeting_waiting_refresh-btn shadow-sm clickable" onClick={()=>checkMeeting()}> <i className="fa-solid fa-refresh" />
                Refresh</button>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>

    </>
  )
}