import React, { useState, useCallback, useRef } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import API from "../../Network/API";
import { ADD_MESSAGE_SUCCESS } from "../../Reducer/userInboxReducer";
import { useDispatch } from "react-redux";
import { MESSAGE_FETCH_ECHO } from "../../Reducer/messageReducer";
import { useEffect } from "react";

export default function FileSend(props) {
    const fileObj = [];
    const fileArrayAnotherImage = [];
    const fileArrayAnother = [];
    const fileArrayVideo = [];
    const fileArray = [];
    const [isLoading, setLoading] = useState(false);
    const [progress, setProgress] = useState(false);
    const [message, setMessage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [files, setFile] = useState([]);
    const [fileArrayImage, setFileArray] = useState([]);
    const [post_modalDialog, setPostShareModalDialog] = useState(false);
    const fileInput = React.useRef();

    const myArr = JSON.parse(localStorage.getItem('user_data'));
    let msgText = "Write a message...";
    const dispatch = useDispatch();
    const removeImage = (url, indexNumber) => {
        const index = files.indexOf(url);
        if (index > -1) { // only splice array when item is found
            files.splice(index, 1); // 2nd parameter means remove one item only
        }
        setFile([...files, files])
        removeElement(indexNumber)

    }

    const removeElement = (index) => {
        const filesf = files.filter((_, i) => i !== index);
        const filesOrginal = fileArrayImage.filter((_, i) => i !== index);
        setFile(filesf); // this array reset for display instant image showing
        setFileArray(filesOrginal); // this array reset for upload to server
    };

    const uploadHandlerSecondInput = (e) => {
        setSelectedFile(e.target.files[0]);
        setFileUrl(URL.createObjectURL(e.target.files[0]))
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            let extn = e.target.files[i].name.split('.').pop();

            if (extn.toLowerCase() === 'mp4' || extn.toLowerCase() === 'wmv' || extn.toLowerCase() === 'avi' || extn.toUpperCase() === "MKV" || extn.toUpperCase() === "WEBM" || extn.toUpperCase() === "MOV") {
                fileArrayVideo.push(URL.createObjectURL(fileObj[0][i]));
            } else {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
            }

            if (fileArrayImage.indexOf(fileObj[0][i]) === -1) {
                fileArrayAnother.push(fileObj[0][i])
            }

            if (files.indexOf(URL.createObjectURL(fileObj[0][i])) === -1) {
                fileArrayAnotherImage.push(URL.createObjectURL(fileObj[0][i]))
            }


        }

        setFileArray([...fileArrayImage, ...fileArrayAnother]) // this is for upload to server
        setFile([...files, ...fileArrayAnotherImage]) // this is for display

    }

    const uploadHandler = (e) => {
        setSelectedFile(e.target.files[0]);
        setFileUrl(URL.createObjectURL(e.target.files[0]))
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            let extn = e.target.files[i].name.split('.').pop();

            if (extn.toLowerCase() === 'mp4' || extn.toLowerCase() === 'wmv' || extn.toLowerCase() === 'avi' || extn.toUpperCase() === "MKV" || extn.toUpperCase() === "WEBM" || extn.toUpperCase() === "MOV") {
                fileArrayVideo.push(URL.createObjectURL(fileObj[0][i]));
            } else {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
            }
            fileArrayAnother.push(fileObj[0][i])
            fileArrayAnotherImage.push(URL.createObjectURL(fileObj[0][i]))
        }
        setFileArray(fileArrayAnother) // this is for upload to server
        setFile(fileArrayAnotherImage) // this is for display
    }


    function onCreatePost() {
        if (selectedFile === null && message === null) {
            toast.error("Message is empty");
            return false;
        }

        const payload = {
            temp_id: props.to_id,
            from_id: myArr.id,
            to_id: props.to_id,
            message: message,
            sender: { name: myArr.name, avatar: myArr.avatar },
            created_at: "now"
        }

        dispatch(ADD_MESSAGE_SUCCESS(payload))

        const fd = new FormData();
        if (selectedFile != null) {
            let fileExtention = selectedFile.name.split('.').pop();
            if (fileExtention.toUpperCase() == "MP4" || fileExtention.toUpperCase() == "WMV" || fileExtention.toUpperCase() == "AVI" || fileExtention.toUpperCase() == "MKV" || fileExtention.toUpperCase() == "WEBM" || fileExtention.toUpperCase() == "MOV") {
                if (selectedFile) {
                    fd.append('files[]', selectedFile, selectedFile.name);
                }
            } else {
                if (fileArrayImage) {
                    for (var i = 0; i < fileArrayImage.length; i++) {
                        fd.append('files[]', fileArrayImage[i], fileArrayImage[i].name);
                    }
                }
            }
        }

        fd.append('to_id', props.to_id);
        fd.append('message', message);
        if (props.is_group == 1) {
            fd.append('is_group', 1);
        }

        setLoading(true)
        API.post("/send_chat", fd, {
            onUploadProgress: progressEvent => {
                // console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
                const tt = response.data.data.message;

                const payload = {
                    last_message_id: tt.id,
                    name: tt.is_group == 1 ? tt.receiver.name : tt.sender.name,
                    image: tt.is_group == 1 ? tt.receiver.avatar : tt.sender.avatar,
                    to_id: tt.is_group == 1 ? tt.to_id : tt.from_id,
                    is_group: tt.is_group,
                    message: tt.message,
                    url_details: tt.url_details,
                    seen_at: tt.created_at,
                    last_messages: null,
                    is_online: tt.sender.is_online,
                    unread_count: 0,
                    created_at: tt.created_at,
                }

                dispatch(MESSAGE_FETCH_ECHO(payload))
                // dispatch(ADD_MESSAGE_SUCCESS(response.data.data.message))

                props.handleShowB()
                props.childToParent(response.data.data.message)
            } else if (response.data.success === false) {
            }
        }).catch((err) => {
            setLoading(false)
        });

    }

    useEffect(() => {
        if (props.dargDrop) {
            const fileObject = props.dargDropFile;
            setSelectedFile(fileObject);
            setFileUrl(URL.createObjectURL(fileObject))
            setFileArray([...fileArrayImage, fileObject])
            setFile([...files, URL.createObjectURL(fileObject)])
        }
       
    }, [props.dargDrop]);

    const dropZoneRef = useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
     
      };

      const handleDrop = (e) => {
        e.preventDefault();
        const fileObject = e.dataTransfer.files[0];
        setSelectedFile(fileObject);
            setFileUrl(URL.createObjectURL(fileObject))
            setFileArray([...fileArrayImage, fileObject])
            setFile([...files, URL.createObjectURL(fileObject)])
      }

    return (<>
        <Modal
            isOpen={props.isModalVisible}
            size=""
            toggle={props.handleShowB}
            className={'modal-dialog-scrollable'}
        >
            <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                Upload Photo/Video
            </ModalHeader>
            <ModalBody ref={dropZoneRef}
      onDrop={handleDrop}
      onDragOver={handleDragOver}>
                <div className="share-creation-state d-none">
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="image">
                            <a href="javascript:void(0)">
                                <div className="user-picture-container rounded-circle"
                                    style={{ background: `url(${myArr.avatar})` }} />
                            </a>
                        </div>
                        <div className="info ms-2">
                            <span className="name">
                                <a href="javascript:void(0)" className="text-dark">{myArr.name}</a>
                            </span>
                            <div className="dropdown">
                                <button
                                    className="btn btn-share_post_privacy dropdown-toggle rounded-pill border border-dark"
                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <i className="fa-solid fa-globe me-1" /> Friend
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="javascript:void(0)">Friend</a></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="share_post_write_text">
                    <form >
                        <div className="form-group">
                            <textarea  name="message" className="form-control write_textarea"
                                placeholder={msgText} defaultValue="" spellCheck="false" onChange={(e) => setMessage(e.target.value)} />
                        </div>
                    </form>
                </div>

                <div className="addPhotosVideo_content">

                    {files.length <= 0 ?
                        <div className="position-relative">

                            <div className="Add_PhotosVideo_btn position-relative">
                                <input className="PhotosVideo-choose-file" type="file" onChange={uploadHandler}
                                    multiple={true} accept="audio/*,video/*,image/*" />
                                <div className="Add_PhotosVideo-body">
                                    <span className="Add_PhotosVideo-icon"><i
                                        className="fas fa-photo-video me-1" /></span>
                                    <h6>Add Photos/Videos</h6>
                                    <p>or drag and drop</p>
                                </div>
                            </div>

                            <div className="photosVideo_close_btn position-absolute">
                                <i className="fas fa-times-circle" />
                            </div>
                        </div>

                        :
                        (fileArrayImage || []).map((url, index) => (
                            <div className="position-relative" key={index} id={url}>

                                {url.type == 'image/png' || url.type == 'image/jpeg' || url.type == 'image/jpg' || url.type == 'image/gif' ?
                                    <div className="post-image">
                                        <img className="img-fluid" key={index} src={window.URL.createObjectURL(url)} alt="image"
                                            style={{ height: '100%', width: '100%', padding: "10px", objectFit: 'fill' }} />

                                    </div>
                                    : url.type == 'video/mp4' ?
                                        <div class="post-video">
                                            <a class="post-video-btn mb-2">
                                                <video class="w-100 h-100">
                                                    <source src={window.URL.createObjectURL(url)} type="video/mp4" />
                                                </video>
                                            </a>

                                        </div>
                                        :
                                        ''
                                }
                                <div className="photoAddButton position-absolute ">
                                    {index === 0 ?
                                        <div className="Add_Photos_btn">
                                            <input className="choose-file" type="file" onChange={uploadHandlerSecondInput}
                                                multiple={true} accept="audio/*,video/*,image/*" />
                                            <span>
                                                <i className="fas fa-photo-video me-1" />
                                                Add Photos/Videos
                                            </span>
                                        </div>
                                        : ''}

                                </div>
                                <div className="photos_close_btn position-absolute ">
                                    <i className="fas fa-times-circle text-dark"
                                        onClick={() => removeImage(url, index)} />
                                </div>

                            </div>

                        ))

                    }

                </div>

            </ModalBody>
            <ModalFooter className={'post-footer-comment-icon'}>
                <button className="btn btn-primary rounded-1" onClick={onCreatePost}>Send {isLoading ? (
                    <span
                        className="spinner-border spinner-border-sm ml-5"
                        role="status"
                        aria-hidden="true"
                    ></span>
                ) : (
                    <span></span>
                )}</button>

            </ModalFooter>
        </Modal>
    </>)
}