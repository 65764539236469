import React, { Fragment, useEffect, useState } from "react";
import FriendRequestAccept from "../../Props/FriendRequestAccept";
import FriendMessage from "../../Props/FriendMessage";

import { Link, useParams } from "react-router-dom";
import Notification from "./Notification";
import API from "../../Network/API";

import AutoSearch from "../../Props/AutoSearch";
import { useDispatch, useSelector } from "react-redux";
import Agent from "../../Network/Agent";




export default function Nav() {

    const user = useSelector((state) => state.userReducer?.data)
    const [search_data, setSearchData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        Agent.Notification.latest(dispatch);
        Agent.UserStory.all(1, dispatch);
        Agent.SuggestVideos.all(1, dispatch);
    }, []);

    const fetch_search = (value) => {
        if (value === "") {
            setLoading(false)
            return 0;
        }

        API.get('search_profile?q=' + value).then((response) => {
            setLoading(true)
            setSearchData(response.data.data)
        }).catch((error) => {
            setLoading(false)
        });
    }
    const clear_search = () => {
        setLoading(false)
    }

    const _logout = () => {
        API.get(`/logout`)
            .then(res => {
                if (res.data.success === true) {
                    localStorage.clear();
                }
            })
        localStorage.clear();
        window.location.href = '/login';
    }

    // const message = useSelector((state) => state.messageReducer?.data)
    const selector = useSelector((state) => state.messageReducer);
    const InboxList = selector?.users;
    const strAscending = [...InboxList].sort((a, b) =>
        a.last_message_id < b.last_message_id ? 1 : -1,
    );

    // const sum = InboxList.reduce((total, item) => {return total + + item.unread_count}, 0)
    const sumUnreadMessages = () => {
        let unreadMessageCount = 0;
        InboxList.forEach(message => {
            // console.log(message.unread_count)
          if (message.unread_count > 0) {
            unreadMessageCount++;
          }
        });
        return unreadMessageCount;
      }

      const auth_user = useSelector((state) => state.authReducer)
    return (
        <Fragment>
            <div className="navbar-area bg-white shadow-sm">
                <div className="main-navbar">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <div className="container-fluid ">
                            <Link to={"/"} className="navbar-brand d-flex align-items-center">
                                <img src="/assets/img/logo.png" alt="image" />
                            </Link>
                            <div className={`collapse navbar-collapse  `} id="navbarSupportedContent">
                                <div className={`search-box m-auto ${auth_user?.accessToken==false?'d-none':''}`}>
                                    <form>
                                        <input type="text" className="input-search" onChange={(e) => fetch_search(e.target.value)} placeholder="Search people" />

                                        {isLoading ?
                                            <div className="search-icon clickable" onClick={() => clear_search()}>
                                                <i className="fa-solid fa-remove" />
                                            </div>
                                            :
                                            <div className="search-icon">
                                                <i className="fa-solid fa-magnifying-glass" />
                                            </div>
                                        }

                                    </form>
                                    {search_data.length > 0 && isLoading && (
                                        <AutoSearch data={search_data} />
                                    )}
                                </div>

                                <div className={`others-options d-flex align-items-center ${auth_user?.accessToken==false?'d-none':''}`}>
                                    <FriendRequestAccept />

                                    <div className="option-item">
                                        <div className="dropdown messages-nav-item">
                                            <Link to={''} className="dropdown-bs-toggle" role="button"
                                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <div className="messages-count">
                                                    <i className="fa-solid fa-comment-dots" />
                                                    {sumUnreadMessages() > 0 ? <span>{sumUnreadMessages()}</span> :''}
                                                </div>
                                            </Link>
                                            <div className="dropdown-menu">
                                                <div
                                                    className="messages-header d-flex justify-content-between align-items-center">
                                                    <h3>Messages</h3>
                                                    <i className="fa-solid fa-ellipsis" />
                                                </div>
                                                {/* <div className="messages-search-box">
                                                    <form>
                                                        <input type="text" className="input-search"
                                                               placeholder="Search Message..."/>
                                                        <button type="submit">
                                                            <i className="fa-solid fa-magnifying-glass"/>
                                                        </button>
                                                    </form>
                                                </div> */}
                                                <div className="messages-body">
                                                    {!selector.loading && InboxList?.length > 0 ?
                                                        strAscending?.map((value, index) => (

                                                            <FriendMessage key={index} value={value} />
                                                        ))
                                                        : ''}
                                                    <div className="view-all-requests-btn text-center ">
                                                        <Link to={'/messages'} className="btn default-btn w-100">View All
                                                            Messages</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    <Notification />
                                    <div className="option-item">
                                        <div className="dropdown profile-nav-item">
                                            <a href="javascript:void(0)" className="dropdown-bs-toggle" role="button"
                                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <div className="menu-profile d-flex align-items-center">
                                                    <span className="name">{localStorage.getItem("profile_name")}</span>
                                                    <div className="user-picture-container rounded-circle ms-3"
                                                        style={{ backgroundImage: `url(${localStorage.getItem("profile_img") ?? "https://via.placeholder.com/50x50.png"})` }} />
                                                    <span className="status-online" />
                                                </div>
                                            </a>
                                            <div className="dropdown-menu">
                                                <div className="profile-header">
                                                    <h3>{user.name}</h3>
                                                   
                                                </div>
                                                <ul className="profile-body">
                                                    <li><i className="fa-solid fa-user" /> <Link to={'/me'}>My Profile</Link>
                                                    </li>

                                                    <li><i className="fa-solid fa-circle-question" /> <Link to={'/terms'}>Terms</Link></li>
                                                    <li><i className="fa-solid fa-shield" /> <Link to={'/policy'}>Privacy</Link></li>
                                                    <li><i className="fa-solid fa-power-off" /> <a href="javascript:void(0)" onClick={() => _logout()}>Logout</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </Fragment>
    );
}