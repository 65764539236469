import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  accessToken: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    USER_LOGGED_IN: (state, action) => {
      state.user = action.payload;
      state.accessToken = true;
    },
    USER_LOGGED_OUT: (state) => {
      state.user = [];
      state.accessToken = false;
    },
  },
});

export const { USER_LOGGED_IN, USER_LOGGED_OUT } = authSlice.actions;
export default authSlice.reducer;