import { createSlice } from '@reduxjs/toolkit';

export const suggestedVideoSlice = createSlice({
    name: 'vidoe_post',
    initialState: {
        loading: true,
        demo_loading: true,
        data: {},
        main_data: {},
        post_data: [],
        localStorageItems: [],
        error: false,
    },
    reducers: {
        SUGGESTED_VIDOE_POST_FETCH_START: (state) => {
            state.loading = true
        },
        SUGGESTED_VIDOE_POST_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.demo_loading = false
            state.data = action.payload
            state.main_data = action.payload
            state.post_data=action.payload.data

            state.localStorageItems = action.payload.data
            .flatMap(post => post?.videos.map(dd => dd.video_stream))
            .filter(videoStream => videoStream !== undefined);

        },
        SUGGESTED_VIDOE_POST_FETCH_SUCCESS_LOAD: (state, action) => {
            console.log(action.payload.data)
            state.loading = false
            state.main_data = action.payload
            state.data = action.payload
            state.post_data=[ ...state.post_data, ...action.payload.data]
            state.localStorageItems = state.post_data
            .flatMap(post => post?.videos.map(dd => dd.video_stream))
            .filter(videoStream => videoStream !== undefined);
        },
        SUGGESTED_VIDOE_POST_FETCH_SUCCESS_LOAD2: (state, action) => {
     
            state.loading = false
            state.main_data = action.payload
            state.data = action.payload
            state.post_data=[ ...state.post_data, ...action.payload]
            state.localStorageItems = state.post_data
            .flatMap(post => post?.videos.map(dd => dd.video_stream))
            .filter(videoStream => videoStream !== undefined);
        },
        ADD_SUGGESTED_VIDOE_POST_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.post_data=[action.payload,...state.data]
        },
        SUGGESTED_VIDOE_POST_FETCH_COMMENT_ADDON: (state, action) => {
            // console.log(action.payload)
            state.loading = false
            state.post_data=state.post_data.map((post) =>
            post.id === action.payload.post_id
              ? post.last_comments.push(action.payload) && post
              : post
          )
        },
        

        SUGGESTED_VIDOE_DELETE_COMMENT:(state,action)=>{
            const Post = state.post_data.find(
                (pst) => pst.id === action.payload.post_id
              );
              Post.last_comments.filter((com)=>com.id !== action.payload.comment_id);
              state.loading = false
              state.post_data=state.post_data.map((pst) =>
              pst.id === action.payload.post_id ? Post : pst
            )
        },

        SUGGESTED_VIDOE_DELETE_POST:(state,action)=>{
            const filterPosts = state.post_data.filter(
                (post) => post.id !== action.payload
              );
              state.loading = false
            state.post_data=filterPosts
        },

        ADD_SUGGESTED_VIDOE_REPLY:(state,action)=>{
           
            state.post_data=state.post_data.map((post) =>
            post.id === action.payload.post_id
              ? post.last_comments.map((comment) =>comment.id === action.payload.comment_id?comment.comment_reply.push(action.payload):comment) && post
              : post
          )
             
        },

        DELETE_SUGGESTED_VIDOE_REPLY:(state,action)=>{
            
            const Post = state.post_data.find(
                (pst) => pst.id === action.payload.post_id
              );
              console.log('rp='+action.payload.comment_reply_id);
              Post.last_comments.map((com)=>com.comment_reply.splice(com.comment_reply.findIndex((fruit) => fruit.id === action.payload.comment_reply_id),1));
              
              state.post_data=state.post_data.map((pst) =>
              pst.id === action.payload.post_id ? Post : pst
            )
            
        },

        SUGGESTED_VIDOE_POST_FETCH_ERROR: (state) => {
            state.error = true
        },
        
    }
});

export const {SUGGESTED_VIDOE_POST_FETCH_SUCCESS_LOAD2,SUGGESTED_VIDOE_POST_FETCH_START,SUGGESTED_VIDOE_POST_FETCH_SUCCESS,SUGGESTED_VIDOE_POST_FETCH_SUCCESS_LOAD,SUGGESTED_VIDOE_POST_FETCH_COMMENT_ADDON,SUGGESTED_VIDOE_DELETE_COMMENT} = suggestedVideoSlice.actions;
export default suggestedVideoSlice.reducer;