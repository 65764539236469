import React, { useEffect, useState } from "react";
import API from "../Network/API";
import { useParams } from "react-router-dom";
import Post from "../Props/Post";

import { SINGLE_POST_FETCH_ERROR, SINGLE_POST_FETCH_SUCCESS } from "../Reducer/singlePostReducer";
import { useDispatch, useSelector } from "react-redux";
import PublicPost from "../Props/PublicPost";
import PostLoader from '../assets/img/loader.gif';
export default function SinglePost() {
 
    let { id } = useParams();
    const dispatch=useDispatch();
    const auth_user = useSelector((state) => state.authReducer)
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        if(auth_user?.accessToken==false){
            API.get(`/v2/get_post/${id}`).then(response => {
                dispatch(SINGLE_POST_FETCH_SUCCESS(response.data.data));
                setLoading(false)
            }).catch((error) => {
                dispatch(SINGLE_POST_FETCH_ERROR());
            });

        }else{
            API.get(`/get_post_comments/${id}`).then(response => {
                dispatch(SINGLE_POST_FETCH_SUCCESS(response.data.data));
                setLoading(false)
            }).catch((error) => {
                dispatch(SINGLE_POST_FETCH_ERROR());
            });
        }

      
    }, [id,auth_user?.accessToken]);

    const state = useSelector((state) => state.singlePostReducer)

    return (
        <>

{isLoading? 
           <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center' }}>
           <img src={PostLoader} alt='Post loader' height={80} width={80} />
       </div>
        :
            <div className="row d-flex justify-content-center">
                <div className="col-md-10">
            <div className="story-postbox news-feed-area">
                <div className="row justify-content-md-center">
                    <div className="col-md-10">
                        {auth_user?.accessToken==false?
                        <PublicPost key={1} data={state.post_data} />
                        :
                        <Post key={1} data={state.post_data} />
                        
                        }

                    </div>
                </div>
            </div>
            </div>
            </div>
}
        </>
    )

}