import PostLoader from '../../assets/img/loader.gif';
import React, { useEffect, useRef, useState } from "react";
import API from "../../Network/API";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { story_show } from '../../Network/EndPoint';
import { toast } from 'react-toastify';

export default function SingleStory(){
    let { id } = useParams();
    const dispatch=useDispatch();
    const auth_user = useSelector((state) => state.authReducer)
    const [prevPostId, setPrevPostId] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [currentPost, setCurrentPost] = useState(null);
    const [current_index, setCurrentIndex] = useState(0);
    const selector_story = useSelector((state) => state.storyReducer)
    const post_data = selector_story?.post_data;
    const [page, setPage] = useState(1);
    const [nextPostId, setNextPostId] = useState(null);
    const [set_like, setLike] = useState(0);
    const [love_react, setLoveReact] = useState(false);
    const [p_id_like, setPIDLike] = useState(0);
    const [message, setMessage] = useState(null);
    const inp_message=useRef();
    useEffect(() => {
        API.get(`${story_show}/${id}`).then(response => {
        }).catch((error) => {
        });
    }, [id]);

    useEffect(() => {
      fetchCurrentPost(id);
  
  }, [id, post_data]);

  const fetchCurrentPost = (postId) => {
      const post = post_data?.find((post) => post.user_id === parseInt(postId, 10));
      setCurrentPost(post);
  };

  const handlePrevClick = () => {
    if (current_index < 0) {
        return;
    }
    const prevIndex = (current_index - 1 + post_data.length) % post_data.length;
    setCurrentIndex(current_index - 1);
    setPrevPostId(post_data[prevIndex].user_id);
    fetchCurrentPost(post_data[prevIndex].user_id);
};

const handleNextClick = () => {
  console.log(post_data.length)
  if (post_data.length < current_index) {
      return;
  }
  const nextIndex = (current_index + 1) % post_data.length;
  setCurrentIndex(current_index + 1);
  setNextPostId(post_data[nextIndex].user_id);
  fetchCurrentPost(post_data[nextIndex].user_id);
  const lastPageIndex = post_data.length - 1;

  if (lastPageIndex >= 0 && current_index == lastPageIndex) {
      setPage(page + 1)
  }

};

const postLike = (post_id) => {
  setLoading(true)
  setLoveReact(true)
  setLike(1)
  setPIDLike(post_id)
  API.post("/v2/story_love", {
    story_id: post_id,
  }).then((response) => {
      setLoading(false)
      setLoveReact(false)
      
  });
}

const sendMessage = (post_id) => {
  if(message==null || message==undefined){
    toast.error("Please say something");
    return;
  }
  setLoading(true)
  API.post("/v2/story_love", {
    story_id: post_id,
    message: message,
    type: 2,
  }).then((response) => {
    setLoading(false)
    setMessage("")
    inp_message.current.value="";
    toast.success("Message send successfully");
  }).catch((error) => {
    setLoading(false)
  });

}

    return (
        <>
        {selector_story?.loading? 
           <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center' }}>
           <img src={PostLoader} alt='Post loader' height={80} width={80} />
       </div>
        :
<section className="bg-black vh-100">
        <div className="daily-stories">
          <div className="daily-stories__outer">
            <div className="daily-stories__container">
              <div className="daily-stories-slide">
                <div className="story-main-image-bg" style={{ backgroundImage: `url(${currentPost?.image})` }} />
                <div className="daily-stories-user">
                  <div className="d-flex">
                    <div className="image">
                      <a href="javascript:void(0)">
                        <div className="user-picture-container rounded-circle" style={{ backgroundImage: `url(${currentPost?.avatar})`, objectFit: 'fill' }} />
                      </a>
                    </div>
                    <div className="info ms-2">
                      <span className="name d-block ">
                        <a href="javascript:void(0)" className="text-white"> {currentPost?.post_by}</a>
                      </span>
                      <small className="small-text text-white"><a href="javascript:void(0)" className="text-white">{currentPost?.last_seen}</a></small>
                    </div>
                  </div>
                </div>
                <div className="daily-stories-content">
                  <p>{currentPost?.message}</p>
                </div>
              </div>
              <div className="story-replay">
                <div className="d-flex align-items-center">
                  <div className="story-replay-reaction-icon d-flex align-items-center me-3">
                    <div className="icon-wrap me-2">
                    {currentPost?.is_loved ?
                                        <i className="fa-solid fa-heart i-loved" />
                                        :
                                        <>
                                            <i onClick={() => postLike(currentPost?.id)} className={`fa-solid fa-heart clickable ${p_id_like == currentPost?.id ? 'i-loved' : ''}`} />
                                        </>
                                    }
                  
                    </div>
                    <span className="like-count">{currentPost?.total_love + (p_id_like == currentPost?.id ? set_like:0)}</span>
                  </div>
                  <div className="story-replay-input position-relative">
                    <input type="text" className="form-control story-replay-input-custom " placeholder="Write a massage..." onChange={(e)=>setMessage(e.target.value)} ref={inp_message} defaultValue={message} />
                    <a href className="story-replay-send-btn position-absolute" onClick={()=>sendMessage(currentPost?.id)}>
                      
                      {isLoading ? (
                    <span
                        className="spinner-border spinner-border-sm ml-5"
                        role="status"
                        aria-hidden="true"
                    ></span>
                ) : (
                  <i className="fa-solid fa-paper-plane" />
                )}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className="story-prev-slide" onClick={handlePrevClick}/>
        <span className="story-next-slide" onClick={handleNextClick}/>
        <div class="story-view-close-btn">
        <Link to={`/`}><i class="fa-solid fa-xmark"/></Link>
    </div>
      </section>
}
        </>
    )

}