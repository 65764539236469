
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import API from "../../Network/API";
import TimeZone from "../../CustomHooks/TimeZone";
import useFetch from "../../CustomHooks/useFetch";

export default function MeetingShare(props) {
  const [modalDialog, setModalDialog] = useState(false);
  const [data, setData] = useState(null);
  const [name, setName] = useState(null);
  const [isLoading, setLoad] = useState(false);
  const [user_select_data, setUserSelectData] = useState([]);
  const myArr = JSON.parse(localStorage.getItem('user_data'));
  const domainName = window.location.origin;
  const toggleModal = () => {
    setModalDialog(!modalDialog)
  };

  const [loading, setLoading] = useState(false);
  const [user_id, setUserId] = useState([]);
  const [meeting_link, setMeetingLink] = useState(null);
  useEffect(() => {

  }, []);

  const clear_search = () => {
    setLoad(false)
  }

function search_friend(e) {
    setName(e)
    if (e !== "" || e !== null) {
      setLoad(true)
      API.get(`/v3/search_friend?q=${e}`).then((response) => {
        setLoad(true)
        setData(response.data.data)
        console.log(response.data.data);
      }).catch((error) => {
        setLoad(false)
      });
    }

  }

  const AddUserId = (event) => {
    if (event.target.checked) {
      if (user_id.indexOf(parseInt(event.target.value)) === -1) {
        user_id.push(parseInt(event.target.value))
        user_select_data.push({
          'id': parseInt(event.target.value),
          'name': event.target.title,
        })
      }
    } else {
      removeID(parseInt(event.target.value))
    }
    setLoad(false)
  }

  const AddGroupId = (event) => {
    if (event.target.checked) {
      if (user_id.indexOf(event.target.value) === -1) {
        user_id.push(event.target.value)
        user_select_data.push({
          'id': event.target.value,
          'name': event.target.title,
        })
      }
    } else {
      removeID(event.target.value)
    }
    setLoad(false)
  }

  const removeUser = (sl, u_id) => {
    // const sdad=user_select_data
    // const index = sdad.findIndex(key => key.id === u_id)
    // sdad.splice(index,1)
    setUserSelectData(user_select_data.filter(a =>
      a.id !== u_id
    ))
    removeID(u_id)
  }

  const removeID = (url) => {
    const index = user_id.indexOf(url);
    if (index > -1) { // only splice array when item is found
      user_id.splice(index, 1); // 2nd parameter means remove one item only

    }
    setUserId(user_id)

  }


  const checkedState = (id) => {
    // const index = user_id.indexOf(id);
    return user_id.find((element) => {
      return element === id;
    })
    // if (index > -1) { // only splice array when item is found
    //  return true;
    // }
    //  return false;
  }

  function _copy(room_name) {
    var text = room_name;
    window.navigator.clipboard.writeText(text).then(function () {
      toast.success('Meeting link copied');
    }, function (err) {
      console.error('Could not copy meeting link', err);
    });
  }


  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);
    var copyText = document.getElementsByClassName("myInput");
    var data = {
      'user_id': user_id,
      'room_id': props.type === 1 ? props.shareData?.room:props.shareData?.room_name,
      'message': props.type === 1 ? `${domainName}/meeting/${props.shareData?.room}`:`${copyText[0].textContent+'\n\n'+copyText[1].textContent+'\n'+copyText[2].textContent+'\n'+copyText[3].textContent+'\n\n'+copyText[4].textContent+'\n'+copyText[5].textContent+'\n'+copyText[6].textContent}`,
      'message_type': 10,
    }
    API.post('/v3/meeting_share', data, {
      onUploadProgress: progressEvent => {
        //  console.log("Progress: " + progressEvent.loaded / progressEvent.total);
      }
    }).then(response => {
      setLoading(false);
      if (response.data.success === true) {
        if (props.type === 1) {
           _start_meeting(props.shareData?.room)
        } else {
          props.handleShow()
          toast.success('Meeting share successfully');
          _schdeule_done()
        }
      }
    }).catch((error) => {
      setLoading(false);
      toast.error('Please try again.');
    });
  }

  const _start_meeting = (meeting_id) => {
    if (meeting_id) {
      window.location.href = "/meeting/" + meeting_id;
    } else {
      toast.error("Please enter meeting id");
    }
  }

  const _schdeule_done = () => {
    window.location.href = "/meeting";
  }

  return (
    <>
      <Modal
        isOpen={props.modalVisible}
        size=""
        toggle={props.handleShow}
        className={'modal-dialog-scrollable popup-max-height'} backdrop="static">
        <ModalHeader className="bg-light" toggle={props.handleShow} id={'staticBackdropLabel'}>
          Your meeting is ready
        </ModalHeader>
        <ModalBody className="bg-light">
          <div className="meeting-schedule-popup">
            <form onSubmit={handleSubmit}>

              <div className="meeting-invite-popup">

                {props.type === 1 ?
                  <div className="row mb-3">
                    <div className="col-12">
                      <label htmlFor="i_Friends" className="form-label form-label-font-14">Meeting Link</label>
                      <div className="meeting-invite-content">
                        <p>{props.shareData?.note}</p>
                        <div className="link-copy bg-white p-2 rounded">
                          <div className="d-flex justify-content-between align-items-center px-2">
                            <p className="m-0">{domainName}/meeting/{props.type === 1 ? props.shareData?.room : props.shareData?.room_name}</p>
                            <a className={'clickable'} onClick={() => _copy(`${domainName}/meeting/${props.type === 1 ? props.shareData?.room : props.shareData?.room_name}`)}> <i className="fas fa-copy" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="form-control" style={{ border: '2px solid #ccc', letterSpacing: '.03em', color: '#131619' }}>
                    <p className="m-0 p-0 text-dark myInput">{myArr?.name} is inviting you to a scheduled connects meeting.</p>
                    <br />
                    <p className="m-0 p-0 text-dark myInput">Topic: {props.shareData?.room_title}</p>
                    <p className="m-0 p-0 text-dark myInput">Time: {new Date(props.shareData?.start_date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</p>
                    <p className="m-0 p-0 text-dark myInput">Timezone: {props.shareData?.time_zone}</p>
                    <br />
                    <p className="m-0 p-0 text-dark myInput">Join Connect Meeting</p>
                    <p className="m-0 p-0 text-dark myInput">{domainName}/meeting/{props.shareData?.room_name}</p>
                    <p className="m-0 p-0 text-dark myInput">Meeting ID: {props.shareData?.room_name}</p>
                  </div>
                }


                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Invite Contact</label>
                    <div className="dropdown w-100" id="i_Friends">
                      <input onChange={(e) => search_friend(e.target.value)} className="btn bg-white text-secondary text-start invite_f_dropdown dropdown-toggle w-100" aria-expanded="false" placeholder="Search Contact" />

                      {name !== '' && isLoading ?
                        <div className="search-item shadow-sm" id="search-item" style={{ display: 'block', height: 'auto' }}>
                          <div className="search-list">
                            <div className="recent-chat">
                              <ul className="list-group list-group-flush">


                                {data?.users?.map((value, index) => (

                                  <li className="list-group-item " key={index}>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div className="reaction-pp d-flex align-items-center">
                                        <div className="recent-chat-user-img me-2">
                                          <a href={`/contact/${value.user_id}`}>
                                            <div className="user-picture-container rounded-circle"
                                              style={{ background: `url("${value.avatar}")` }} />
                                          </a>
                                        </div>
                                        <div className="name">
                                          <a href={`/contact/${value.user_id}`}
                                            className="text-dark">{value.name}</a>
                                          <p style={{ fontSize: '12px' }}>Hey there! I am using connects</p>
                                        </div>
                                      </div>
                                     
                                      <div className="reaction-btn">

                                        <input  name={`user[${value.user_id}]`} className="form-check-input"
                                          title={value.name} value={value.user_id} type="checkbox"
                                          onClick={(e) => AddUserId(e)}
                                          id={`custom-checkbox-${index}`} />

                                      </div>
                                    </div>
                                  </li>
                                ))}

<li>
<b className="fs-12 me-2 ms-2">Other Contact Groups</b>
</li>
                            {data?.groups?.map((value, index) => (

                            <li className="list-group-item " key={index}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="reaction-pp d-flex align-items-center">
                                  <div className="recent-chat-user-img me-2">
                                    <a href={`#`}>
                                      <div className="user-picture-container rounded-circle"
                                        style={{ background: `url("${value.avatar}")` }} />
                                    </a>
                                  </div>
                                  <div className="name">
                                    <a href={`#`}
                                      className="text-dark">{value.name}</a>
                                    <p style={{ fontSize: '12px' }}>Hey there! I am using connects</p>
                                  </div>
                                </div>

                                <div className="reaction-btn">
                                  <input  name={`group[${value.id}]`} className="form-check-input"
                                    title={value.name} value={value.id} type="checkbox"
                                    onClick={(e) => AddGroupId(e)}
                                    id={`custom-checkbox-${index}`} />
                                </div>
                              </div>
                            </li>
                            ))}

                              </ul>
                            </div>
                          </div>
                        </div>
                        : ''
                      }



                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  {user_select_data?.length > 0 ?

                    user_select_data.map((username, i) =>
                      <>
                        <span key={i} className="badge bg-white shadow-sm rounded-pill text-dark">{username['name']} <i className="me-1 fas fa-times-circle" onClick={() => removeUser(i, username['id'])} /></span>
                      </>
                    )
                    : ''
                  }


                </div>

              </div>
              <ModalFooter className={'post-footer-comment-icon bg-light'}>

                <button type="submit" className="btn btn-primary">{props.type === 1 ? 'Start' : 'Share'} Meeting{loading ? (
                  <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                ) : (
                  <span></span>
                )}</button>

              </ModalFooter>
            </form>
          </div>
        </ModalBody>

      </Modal>
    </>
  )
}