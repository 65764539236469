import React, {useContext, createContext, useState, Fragment} from 'react';
import API from "../Network/API";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

toast.configure();

export default function NameVerify() {
    const [name, setName] = useState('');
    const [isLoading, setLoading] = useState(false);
    
    
    const handleSubmit = event => {
        event.preventDefault();
        if (typeof name == "") {
            toast.error('Please enter your name!');
            return false;
        }
        setLoading(true);
        var data = {
            'name': name,
        }
        API.post('nameVerify', data, {
            onUploadProgress: progressEvent => {
                // console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then(response => {
            setLoading(false);
            toast.success('Name verify success');
            if (response.data.success === true) {
                localStorage.setItem("profile_name", name);
                if(localStorage.getItem("set_meeting_url")==null && localStorage.getItem("url")==null){
                    window.location.href = "/";
                }
                if(localStorage.getItem("set_meeting_url")!=null && localStorage.getItem("url")==null){
                    window.location.href=localStorage.getItem("set_meeting_url");
                }else if(localStorage.getItem("url")!=null && localStorage.getItem("set_meeting_url")==null){
                    window.location.href=localStorage.getItem("url");
                } else {
                    window.location.href = "/";
                }
    
            }
        }).catch((error) => {
            setLoading(false);
            toast.error('Update Failed.Please try again.');
        });
    }
    return (
        <Fragment>
            <div className="main-wrap ">
                <div className="row">
                    <div className="col-xl-7 bg-white login-100vh-height">
                        <div className="row align-items-center justify-content-center h-100">
                            <div className="col-10 col-md-6 col-lg-6 col-sm-8 col-xl-6">
                                <form onSubmit={handleSubmit}>
                                    <div className="text-center">
                                        <img src="assets/img/logo.png" className="logo-auth"/>
                                        <p className="text-black mt-3">Free messaging, voice and video meeting, social
                                            media and more!</p>
                                        <div className="form-group icon-input mb-3">
                                            <i className="font-sm ti-email text-grey-500 pe-0"/>
                                            <input onChange={(e) => setName(e.target.value)} type="text"
                                                   className="form-control form-control-lg" id="exampleInputEmail1"
                                                   aria-describedby="emailHelp" placeholder="Enter your name"/>
                                        </div>
                                        <span style={{color: 'red'}}/>
                                        <div className="form-group mb-1">
                                            <button type={'submit'} className="btn btn-primary w-100 mb-3 btn-lg" role="button">GET
                                                STARTED {isLoading ? (
                                                    <span
                                                        className="spinner-border spinner-border-sm ml-5"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                ) : (
                                                    <span></span>
                                                )}</button>
                                            <h6 className="text-black-50 fw-500 mt-0 mb-0">
                                                By sign up you're accepting our <Link to={'/page/terms'} target='_blank'>terms
                                                & conditions</Link> and <Link to={'/page/policy'} target='_blank'>policy</Link></h6>
                                        </div>

                                        <div className="col-sm-12 p-0 mt-4">
                                            <div className="mobile-space-between">
                                            <a target="_blank" href="https://apps.apple.com/gb/app/connects-social-and-meeting/id1567949697" className="android">
                                                <img src="assets/img/playstor.svg"/>
                                            </a>
                                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.tryonex.connects" className="android">
                                                <img src="assets/img/appstor.svg"/>
                                            </a>
                                        </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 bg-main-color">
                        <div className="login-img-container"
                             style={{backgroundImage: 'url("assets/img/login-bg.png")'}}/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}