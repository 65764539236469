import { createSlice } from '@reduxjs/toolkit';

export const storySlice = createSlice({
    name: 'storySlice',
    initialState: {
        loading: true,
        main_data: {},
        post_data: [],
        error: false,
    },
    reducers: {
        STORY_FETCH_START: (state) => {
            state.loading = true
        },
        STORY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.main_data = action.payload
            state.post_data=action.payload.data
        },
        STORY_FETCH_SUCCESS_LOAD: (state, action) => {
            state.loading = false
            state.main_data = action.payload
            state.post_data=[ ...state.post_data, ...action.payload.data]
        },
        ADD_STORY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.post_data=[action.payload,...state.data]
        },
        
        STORY_FETCH_ERROR: (state) => {
            state.error = true
            state.loading = false
        },
        
    }
});

export const {STORY_FETCH_START,STORY_FETCH_SUCCESS,STORY_FETCH_SUCCESS_LOAD,ADD_STORY_FETCH_SUCCESS,STORY_FETCH_ERROR} = storySlice.actions;
export default storySlice.reducer;