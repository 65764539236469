import API from "../Network/API";
import React, { Component, Fragment, useEffect, useState, memo } from "react";
import PlaceholderSuggestFriend from "../Network/PlaceholderSuggestFriend";
import ContentLoader from "react-content-loader"
import { Link } from "react-router-dom";

export default function HomeMeeting(props) {
  const [demo_data, setDatas] = useState([{ id: 1 }, { id: 2 }, { id: 3 }]);
  const [MeetingList, setMeetingList] = useState([]);
  const [data_read, setData_read] = useState(false);
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    setProgress(true)
    API.get(`/v1/upcoming_meeting_all`).then(response => {
      setProgress(false)
      setMeetingList(response.data.data.meeting_data)
    }).catch((error) => {
      setProgress(true)
    });

    // API.get(`/get_home_sync`).then(response => {
    //     setProgress(false)
    //     setMeetingList(response.data.data.meeting_history)
    // }).catch((error) => {
    //     setProgress(true)
    // });
  

  }, []);

  return (
    <>
   
    {!progress && MeetingList.length>0?
    <div className="widget widget-meeting-history mb-4">
      <h4>Upcoming meetings</h4>
      <div className="card shadow-sm">
        <div className="meeting-history">
          <ul className="list-group list-group-flush">


            {progress && demo_data?.map((post, i) => (
              <li key={i} className="list-group-item widget-item-list ">
                {/*<PlaceholderSuggestFriend  key={i} backgroundColor="#ffffff"*/}
                {/*                           foregroundColor="#ecebeb" />*/}

                <ContentLoader
                  speed={2}
                  width={400}
                  height={60}
                  viewBox="0 0 400 60"
                  backgroundColor="#eeecec"
                  foregroundColor="#eae6e6"
                  {...props}
                >
                  <circle cx="31" cy="31" r="15" />
                  <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
                  <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
                </ContentLoader>
              </li>
            ))}
            {
              !progress && MeetingList.map((value, index) => (

                <li className="list-group-item widget-item-list ">
                  <div className="upcoming-meeting-list d-flex align-items-center">
                    <div className="upcoming-meeting-date_time">
                      <h2 className="upcoming-meeting-date">{value.day}</h2>
                      <p className="upcoming-meeting-month">{value.month}</p>
                      <p className="upcoming-meeting-time">{value.time}</p>
                    </div>

                    <div className="upcoming-meeting-content">
                      <h4 className="upcoming-meeting-title">
                        {value.room_title}
                      </h4>
                      <div className="upcoming-meeting-user-list">
                        <div className="upcoming-meeting-user-img">
                          <ul>
                            <>
                              {value.users.map((user, index) => (

                                <li>
                                  <Link to={`/contact/${user.id}`}><div className="user-picture-30 rounded-circle" style={{ background: `url(${user.avatar})` }} /></Link>

                                </li>

                              ))}
                            </>
                            {value.users.length > 2 ?
                              <li>
                                <a href="javascript:void(0)">
                                  <div className="upcoming-meeting-user-count">+{value.users.length}</div>
                                </a>
                              </li>
                              : ''}

                          </ul>
                        </div>
                      </div>
                      <Link to={`/meeting-details/${value.room_name}`} className="upcoming-meeting-d-btn">Details<i className="fa fa-angle-right" /> </Link>

                    </div>

                  </div>
                </li>


              ))
            }

          </ul>
        </div>
      </div>
    </div>
    :''}
    </>
  )
}