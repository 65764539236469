import API from "../../Network/API";
import React, { Component, Fragment, useEffect, useRef, useState, memo } from "react";
import ContentLoader from "react-content-loader"
import { toast } from "react-toastify";
import MessageAction from "./MessageAction";
import { Link, useParams } from "react-router-dom";
import UserChatFile from "./UserChatFile";
import MessageNav from "./MessageNav";
import FileSend from "./FileSend";
import { useDispatch, useSelector } from "react-redux";
import {
    ADD_MESSAGE_SUCCESS,
    INBOX_FETCH_SUCCESS,
    DELETE_TEMP_MESSAGE,
    INBOX_FETCH_ERROR,
    DELETE_MESSAGE,
    RECEIVE_SOCKET_MESSAGE,
    RECEIVE_MESSAGE_LOADING
} from "../../Reducer/userInboxReducer";

import { MESSAGE_FETCH_ECHO } from '../../Reducer/messageReducer'
import CallSendPopup from '../../Props/CallSendPopup';
import NewCallSound from '../../assets/audio/NewCall.mp3';
import GroupChatFile from "./GroupChatFile";
import { ADD_CHATID_SUCCESS } from "../../Reducer/ChatListId";
import { MEMBER_FETCH_SUCCESS } from "../../Reducer/groupMemberReducer";
import { ADD_ROLE_SUCCESS } from "../../Reducer/memberRoleReducer";

let gumStream = null;
let recorder = null;
let audioContext = null;
const UserInbox = (props) => {
    const fileObj = [];
    const fileArray = [];
    const fileArrayAnother = [];
    const fileArrayVideo = [];
    const [demo_data, setData] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }]);
    const [progress, setProgress] = useState(false);
    const [Messages, setMessages] = useState([]);
    const [selfId, setSelfId] = useState(null);
    const [friendId, setFriendId] = useState(null);
    const [data_read, setData_read] = useState(false);
    const [text, setText] = useState("");
    const [files, setFiles] = useState([]);
    const [user, setUser] = useState("");
    const [members, setMember] = useState([]);
    const messagesEndRef = useRef(null);
    const textData = useRef();
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [file, setFile] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);
    const fileInput = React.useRef();
    const [isLoading, setLoading] = useState(false);
    const [isRecorde, setRecorde] = useState(false);
    const [isTyping, setTyping] = useState(false);
    const [audio_data, setAudioData] = useState([]);

    const self_data = JSON.parse(localStorage.getItem("user_data"));
    let { id } = useParams();
    const [deviceType, setDeviceType] = useState("");
    const [userId, setUserId] = useState(null);
    const [post_modalDialog, setPostShareModalDialog] = useState(false);
    const [activeUsers, setActiveUsers] = useState([]);
    const [typingUser, setTypingUser] = useState(null);
    const [typingTimer, setTypingTimer] = useState(null);
    const [page, setPage] = useState(0);
    const [has_page, setHasPage] = useState(false);

    const [callStart, setCallStart] = useState(false);
    const [playing, setPlaying] = useState(false);

    const [reply_message_enable, setReplyMessageEnable] = useState(false);
    const [reply_message_id, setReplyMessageId] = useState(0);
    const [reply_message, setReplyMessage] = useState(null);
    const [reply_to, setReplyTo] = useState(null);
    const [group_role, setGroupRole] = useState(0);
    const [heightStyle, setHeightStyle] = useState("mesge-area");
    const [is_darg_drop, setIsDragDrop] = useState(false);
    const [darg_drop_file, setDragDrop] = useState(null);

    const audioRef = useRef(new Audio(NewCallSound));

    const toggleModalFileUpload = () => {
        setPostShareModalDialog(!post_modalDialog)
    };
    const dispatch = useDispatch();
    useEffect(() => {

        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                navigator.userAgent
            )
        ) {
            setDeviceType("Mobile");
        } else {
            setDeviceType("Desktop");
        }
        let timer;


    }, [id]);



    useEffect(() => {
        setProgress(true)
        API.get(`/users/${id}/conversation?is_group=1`).then(response => {

            if (response.data.data.message_pagination.next_page_url == null) {
                setHasPage(false)
            } else {
                setHasPage(true)
            }

            // console.log(response.data.data)
            setSelfId(response.data.data.self)
            // setMessages(response.data.data.conversations.slice().reverse())
            setData_read(true)
            setUser(response.data.data.user)
            setGroupRole(response.data.data.group_role)
            dispatch(ADD_ROLE_SUCCESS(response.data.data.group_role))
            setProgress(false)
            dispatch(INBOX_FETCH_SUCCESS(response.data.data))
            setPage(2)
            scrollToBottom()
        });

        API.get(`/group_chat_resource/${id}`).then(response => {
            setChatHistory(response.data.data.conversations)
            setMember(response.data.data.users)
            dispatch(MEMBER_FETCH_SUCCESS(response.data.data.users))
        });

        scrollToBottom()
        dispatch(ADD_CHATID_SUCCESS(id))
    }, [id]);

    // const member_data = useSelector((state) => state.members)
    // console.log(member_data?.data)
    const member_role = useSelector((state) => state.role)
    const chatReducer = useSelector((state) => state.chatLogs)
    const chats = chatReducer?.messsage
    // const selfId=chatReducer?.self

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    }

    const uploadHandler = (e) => {
        setSelectedFile(e.target.files[0]);
        setFileUrl(URL.createObjectURL(e.target.files[0]))
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            let extn = e.target.files[i].name.split('.').pop();

            if (extn.toLowerCase() === 'mp4' || extn.toLowerCase() === 'wmv' || extn.toLowerCase() === 'avi' || extn.toUpperCase() === "MKV" || extn.toUpperCase() === "WEBM" || extn.toUpperCase() === "MOV") {
                fileArrayVideo.push(URL.createObjectURL(fileObj[0][i]));
            } else {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
            }
            fileArrayAnother.push(fileObj[0][i])
        }

        setFile(fileArrayAnother)

    }

    const onClick = () => {
        fileInput.current.click();
    }

    const deleteMessageEveryOne = (messageId) => {
        // console.log(messageId)
        var data = {
            'message_id': messageId,
        }
        API.post(`conversations/${messageId}/delete/everyone`,data).then(response => {
            setLoading(false);
            dispatch(DELETE_MESSAGE(messageId))
            toast.success('Message delete success');
        }).catch((error) => {
            setLoading(false);
        });
    }

   

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedFile === null && text === null) {
            toast.error("Message is empty");
            return false;
        }

        const fd = new FormData();

        if (selectedFile != null) {
            let fileExtention = selectedFile.name.split('.').pop();
            if (fileExtention.toUpperCase() == "MP4" || fileExtention.toUpperCase() == "WMV" || fileExtention.toUpperCase() == "AVI" || fileExtention.toUpperCase() == "MKV" || fileExtention.toUpperCase() == "WEBM" || fileExtention.toUpperCase() == "MOV") {
                if (selectedFile) {
                    fd.append('files[]', selectedFile, selectedFile.name);
                }
            } else {
                if (file) {
                    for (var i = 0; i < file.length; i++) {
                        fd.append('files[]', file[i], file[i].name);
                    }
                }
            }
        }

        // setMessages([...Messages, {
        //     from_id: selfId.id,
        //     to_id: id,
        //     message: text,
        //     sender: { name: selfId.name, avatar: selfId.avatar },
        //     created_at: "now"
        // }]);

        const payload = {
            temp_id: id,
            from_id: selfId.id,
            to_id: id,
            message: text,
            sender: { name: selfId.name, avatar: selfId.avatar },
            created_at: "1s ago"
        }

        dispatch(ADD_MESSAGE_SUCCESS(payload))


        fd.append('to_id', id);
        fd.append('message', text);
        fd.append('is_group', 1);
        if (reply_message_id != 0) {
            fd.append('reply_to', reply_message_id);

        }
        scrollToBottom();
        setLoading(true)
        API.post("/send_chat", fd, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
            setLoading(false)
            setReplyMessageEnable(false)
            setReplyMessageId(0)
            if (response.data.success === true) {
                const tt = response.data.data.message;

                const payload = {
                    last_message_id: tt.id,
                    name: tt.is_group==1?tt.receiver.name:tt.sender.name,
                    image: tt.is_group==1?tt.receiver.avatar:tt.sender.avatar,
                    to_id: tt.is_group==1?tt.to_id:tt.from_id,
                    is_group: tt.is_group,
                    message: tt.message,
                    url_details: tt.url_details,
                    seen_at: null,
                    last_messages: null,
                    is_online: null,
                    unread_count: 0,
                    created_at: tt.created_at,
                }
              
                dispatch(MESSAGE_FETCH_ECHO(payload))

                dispatch(DELETE_TEMP_MESSAGE(id))
                dispatch(ADD_MESSAGE_SUCCESS(response.data.data.message))
            } else if (response.data.success === false) {

            }
        })
            .catch((err) => {
                setLoading(false)
            });

        setText(null);
        event.target.reset();
        textData.current.focus();
        scrollToBottom();
    }


    const startRecording = () => {
        let constraints = {
            audio: true,
            video: false
        }

        audioContext = new window.AudioContext({
            latencyHint: 'interactive',
            sampleRate: 44100,
        });
        console.log("sample rate: " + audioContext.sampleRate);

        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(function (stream) {
                console.log("initializing Recorder.js ...");
                setRecorde(true)
                gumStream = stream;

                let input = audioContext.createMediaStreamSource(stream);
                recorder = new window.MediaRecorder(gumStream)
                // recorder = new window.Recorder(input, {
                //     numChannels:1
                // })

                recorder.start();
                console.log("Recording started");

            }).catch(function (err) {
                setRecorde(false)
                console.log(err);
                //enable the record button if getUserMedia() fails
            });

    }
    const stopRecording = () => {
        console.log("stop");

        recorder.stop(); //stop microphone access
        // gumStream.getAudioTracks()[0].stop();
        setRecorde(false);
        recorder.ondataavailable = (e) => {
            console.log(e.data)
            setAudioData(e.data)
        };

        recorder.onstop = (blob) => {


            /* Convert the recorded audio to
               blob type mp4 media */
            let blobData = new Blob(blob.Blob, { type: 'audio/webm;codecs=opus' });

            // Convert the blob data to a url
            // let url = URL.createObjectURL(blob)
            // Assign the url to the output video tag and anchor
            // output.src = url
            // anc.href = url
            let data = new FormData();
            data.append('to_id', id);
            data.append('message', 'test audio');
            // data.append('message', "this is the transcription of the audio file");
            data.append('files[]', blobData, "recording.wav");

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            setLoading(true)
            API.post("/send_chat", data, config).then((response) => {
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
            });
        };
        //end

        //recorder.exportWAV(onStop);
    }
    const onStop = (blob) => {
        console.log("uploading...");

        let data = new FormData();

        //data.append('text', "this is the transcription of the audio file");
        data.append('files[]', blob, "recording.wav");

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        // axios.post('http://localhost:8080/asr/', data, config);
    }

    const deleteMessage = (messageId, keyValue) => {
        var data = {
            'message_id': messageId,
        }
        API.post(`conversations/message/${messageId}/delete`, data).then(response => {
            setLoading(false)
            // Messages.splice(keyValue, 1)
            // setMessages(Messages)
            // var elem = document.getElementById("message_id_" + messageId);
            // elem.parentNode.removeChild(elem);
            dispatch(DELETE_MESSAGE(messageId))
            toast.success('Message Delete Successfully')
        }).catch((error) => {
            setLoading(false)
        });
    }

    const clickPhoto = () => {
        // fileInput.current.click();
        toggleModalFileUpload();
    }


    const childToParent = (childdata) => {
        //receive message
        scrollToBottom();
        dispatch(DELETE_TEMP_MESSAGE(id))
        dispatch(ADD_MESSAGE_SUCCESS(childdata))
    }

    const userTyping = () => {

    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {

        } else {
        }
    };


    function loadMore() {
        setProgress(true)
        API.get(`/users/${id}/conversation?page=${page}`).then(response => {
            setPage((page + 1))

            if (response.data.data.message_pagination.next_page_url != null) {
                setHasPage(true)
            } else {
                setHasPage(false)
            }
            setProgress(false)
            dispatch(RECEIVE_MESSAGE_LOADING(response.data.data))
        });
    }

    const playNewCall = () => {
        setPlaying(true);
        audioRef.current.play();
    };

    const pauseNewCall = () => {
        setPlaying(false);
        audioRef.current.pause();
    };

    function startCall(userId) {
        setCallStart(true);
        playNewCall();
        API.post(`send_calling?user_id=${userId}&type=0`).then((response) => {
            // console.log(response);
        }).catch((error) => {
            console.log(error);
        });

        //hide calling ui after 35 second's
        setTimeout(() => {
            setCallStart(false);
        }, 30000);
    }

    function cancelCall() {
        setCallStart(false);
        pauseNewCall();
    }

    // const [reply_message_enable, setReplyMessageEnable] = useState(false);
    // const [reply_message_id, setReplyMessageId] = useState(0);
    // const [reply_message, setReplyMessage] = useState(null);
    // const [reply_to, setReplyTo] = useState(null);
    const replyMessage = (id, message, username) => {
        setReplyMessageEnable(true)
        setReplyMessageId(id)
        setReplyMessage(message)
        setReplyTo(username);
        setHeightStyle("mesge-area-reply");
        setHeightStyle("mesge-area");

    }

    const replyMessageCancel = () => {
        setReplyMessageEnable(false)
        setReplyMessageId(0)
        setReplyMessage(null)
        setReplyTo(null);
    }

    const deleteGroup = (group_id) => {
        setLoading(true);
        var shouldDelete = window.confirm('Do you really want to remove this group?');
        if (!shouldDelete) {
            setLoading(false)
            return false
        }
        API.delete(`chat_groups/${group_id}/remove`).then(response => {
            setLoading(false);
            toast.success('Group remove successfully');
            window.location.href='/messages';
        }).catch((error) => {
            setLoading(false);
        });
    }


    const dropZoneRef = useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
      };

      const handleDrop = (e) => {
        e.preventDefault();
        const imageFile = e.dataTransfer.files[0];
        setDragDrop(e.dataTransfer.files[0])
        setIsDragDrop(!is_darg_drop)
        setPostShareModalDialog(!post_modalDialog)
      }

    return (
        <>
            {callStart &&
                <CallSendPopup onCancel={() => cancelCall()} image={user.avatar} name={user.name} />
            }

            <div className="row">
                {deviceType === 'Desktop' ?
                    <>
                        <div className="col-md-3 d-none d-sm-block">
                            <MessageNav />
                        </div>
                    </> :
                    <div className="col-md-12 d-none d-sm-block">
                        <MessageNav />
                    </div>
                }
                <div className="col-md-6 margin-top-minex">
                    <div className="mesge-area-scroll">
                        <div className="mesg-area-head d-flex justify-content-between align-items-center">

                            <div className="active-user d-flex align-items-center">
                                {deviceType === 'Mobile' ?
                                    <Link to={`/messages`}>
                                        <div className="msg-back clickable">

                                            <i className="fa-solid fa-arrow-left" />

                                        </div>
                                    </Link>
                                    : ''}

                                <div className="mesg-area-head-user-avater">
                                    <div
                                        className="user-picture-container rounded-circle"
                                        style={{ background: `url(${user.avatar})` }}
                                    />
                                </div>
                                <div className="msg-avater-info">
                                    <h6 className="unread">{user.name}</h6>
                                    <span class="text-dark">
                                        {user.users?.map((mb,inx)=>(
                                             <>{mb},</>
                                        ))}
                                    </span>
                                </div>
                            </div>
                            <ul className="mesg-head-right">
                                <li className="msg-search">
                                    <i onClick={() => startCall(user.id)} className="fa-solid fa-phone main-color" />
                                </li>
                                {/* <li className="msg-search">
                                <i className="fa-solid fa-video main-color" />
                            </li> */}

                                <li>
                                    <div className="global-dropdown">
                                        <div className="dropdown">
                                            <button
                                                className="dropdown-toggle dropdown-bg-transparent"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i className="fa-solid fa-ellipsis-vertical" />
                                            </button>
                                            <ul className="dropdown-menu" style={{}}>
                                                <li>
                                                    <a
                                                        className="dropdown-item d-flex align-items-center"
                                                        href="javascript:void(0)"
                                                    >
                                                        <i className="fa-solid fa-pen-to-square" />
                                                        Mark As Unread
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="dropdown-item d-flex align-items-center"
                                                        href="javascript:void(0)"
                                                    >
                                                        <i className="fa-solid fa-eye-slash" /> Archive
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="dropdown-item d-flex align-items-center"
                                                        href="javascript:void(0)" onClick={()=>deleteGroup(user.id)}
                                                    >
                                                        <i className="fa-solid fa-trash-can" /> Delete
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className={heightStyle}>

                            <ul className="conversations ps-container ps-theme-default ps-active-y">
                                {has_page == true ?
                                    <div className="text-center">
                                        <button type="button" className="btn btn-primary rounded-1 btn-sm"
                                            onClick={() => loadMore()}>Load More {progress ? (
                                                <span
                                                    className="spinner-border spinner-border-sm ml-5"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                <span></span>
                                            )}</button>
                                    </div>

                                    : ''}
                                {progress && demo_data?.map((post, index) => (
                                    <>
                                        <li className="you justify-content-start">
                                            <ContentLoader
                                                speed={2}
                                                width={400}
                                                height={80}
                                                viewBox="0 0 400 80"
                                                backgroundColor="#eeecec"
                                                foregroundColor="#eae6e6"
                                                {...props}
                                            >
                                                <circle cx="37" cy="47" r="17" />
                                                <rect x="65" y="36" rx="0" ry="0" width="239" height="8" />
                                                <rect x="66" y="50" rx="0" ry="0" width="198" height="7" />
                                            </ContentLoader>
                                        </li>

                                        <li className="me justify-content-end">
                                            <ContentLoader
                                                speed={2}
                                                width={400}
                                                height={80}
                                                viewBox="0 0 400 80"
                                                backgroundColor="#eeecec"
                                                foregroundColor="#eae6e6"
                                                {...props}
                                            >
                                                <circle cx="37" cy="47" r="17" />
                                                <rect x="65" y="36" rx="0" ry="0" width="239" height="8" />
                                                <rect x="66" y="50" rx="0" ry="0" width="198" height="7" />
                                            </ContentLoader>
                                        </li>
                                    </>

                                ))}

                                {!progress &&
                                    chats?.map((value, index) => (
                                        <>
                                            {selfId?.id === value.from_id ?
                                                <li key={value.id}
                                                    className={`me d-flex ${value.is_reply == 1 ? 'flex-column align-items-end' : ''} justify-content-end message_id_${index}`}
                                                    id={`message_id_${value.id}`}>
                                                        {value.is_reply == 1 ?
                                                            <div className='d-flex justify-content-end'>
                                                               
                                                            <div className={`text-box message-text`}>
                                                           
                                                                
                                                                    <p class="mb-2 me-text">{value.old_message}</p>
                                                                    <p dir="auto">
                                                                        {value.message}
                                                                    </p>
                                                                    <span> {value.created_at}

                                                                        {
                                                                            value.temp_id ?
                                                                                <i className="fa-solid fa-check-double ms-2"
                                                                                    style={{ color: 'rgb(191, 183, 183)' }} />
                                                                                : value.seen_at != 0 ?
                                                                                    <i className="fa-solid fa-check-double ms-2" />
                                                                                    :
                                                                                    <i className="fa-solid fa-check-double ms-2"
                                                                                        style={{ color: 'rgb(255, 255, 255)' }} />
                                                                        }


                                                                    </span>
                                                                
                                                            </div>
                                                            <div className="chat-dropEnd">
                                                                <div className="btn-group dropstart">
                                                                    <button type="button" className="btn p-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu" >
                                                                        <li>
                                                                            <a className="dropdown-item d-flex align-items-center" href="javascript:void(0)" onClick={() => replyMessage(value.id, value.message, value.sender.name)}>
                                                                                <i className="fa-solid  me-2"></i> Reply</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item d-flex align-items-center clickable" onClick={() => deleteMessageEveryOne(value.id)}>
                                                                                <i className="fa-solid me-2" /> Delete for everyone</a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="dropdown-item d-flex align-items-center clickable" onClick={() => deleteMessage(value.id, index)}>
                                                                                <i className="fa-solid  me-2"></i> Delete Message</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        : 

                                                        <div className={`text-box message-text`}>

                                                            

                                                            {value.file_name != null ?
                                                                <>
                                                                    <p dir="auto">
                                                                        {value.message}
                                                                        
                                                                        {value.message_type == 4 ?
                                                                            <div>
                                                                                <audio
                                                                                    controls
                                                                                    src={value.file_name}>
                                                                                        
                                                                                </audio>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                            <img className="d-block" src={value.file_name} alt={value.file_name} />
                                                                            <a className="text-white" target="_blank" href={value.file_name}>Download</a>
                                                                            </div>
                                                                        }
                                                                    </p>

                                                                    <span> {value.created_at}
                                                                        {
                                                                            value.temp_id ?
                                                                                <i className="fa-solid fa-check-double ms-2" style={{ color: 'rgb(191 183 183)' }} />
                                                                                : value.seen_at != null ?
                                                                                    <i className="fa-solid fa-check-double ms-2" />
                                                                                    : <i className="fa-solid fa-check-double ms-2"
                                                                                        style={{ color: 'rgb(255, 255, 255)' }} />
                                                                        }</span>
                                                                </>
                                                                :
                                                                value.message_type == 11 ?
                                                                    <>
                                                                        <div class="text-box message-text">
                                                                            <p class="mb-2 text-decoration-underline text-white">{value.url_details['url']}</p>
                                                                            <div class="link-msg-img">
                                                                                <img class="" src={value.url_details['image']}
                                                                                    alt="" />
                                                                            </div>
                                                                            <p class="mt-2 lh-1 link-msg-dsc">{value.url_details['title']}</p>
                                                                            <p class="mt-2 lh-1 link-msg-dsc">{value.url_details['description']}</p>

                                                                            <span>
                                                                                {value.created_at}

                                                                                {
                                                                                    value.temp_id ?
                                                                                        <i className="fa-solid fa-check-double ms-2"
                                                                                            style={{ color: 'rgb(191 183 183)' }} />
                                                                                        : value.is_seen != 0 ?
                                                                                            <i className="fa-solid fa-check-double ms-2" />
                                                                                            :
                                                                                            <i className="fa-solid fa-check-double ms-2"
                                                                                                style={{ color: 'rgb(255, 255, 255)' }} />
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                    :


                                                                    <>
                                                                        <p dir="auto">
                                                                            {value.message}
                                                                        </p>
                                                                        <span> {value.created_at}

                                                                            {
                                                                                value.temp_id ?
                                                                                    <i className="fa-solid fa-check-double ms-2"
                                                                                        style={{ color: 'rgb(191, 183, 183)' }} />
                                                                                    : value.is_seen != 0 ?
                                                                                        <i className="fa-solid fa-check-double ms-2" />
                                                                                        :
                                                                                        <i className="fa-solid fa-check-double ms-2"
                                                                                            style={{ color: 'rgb(255, 255, 255)' }} />
                                                                            }


                                                                        </span>
                                                                    </>
                                                            }

                                                        </div>
                                                        } 
                                                        {value.is_reply == 1 ?
                                                        ''
                                                        :
                                                        <div className="chat-dropEnd">
                                                            <div className="btn-group dropstart">
                                                                <button type="button" className="btn p-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                                                </button>
                                                                <ul className="dropdown-menu" >
                                                                <li>
                                                                    <a className="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                                                        <i className="fa-solid  me-2"></i>Message Info</a>
                                                                </li>
                                                                    <li>
                                                                        <a className="dropdown-item d-flex align-items-center" href="javascript:void(0)" onClick={() => replyMessage(value.id, value.message, value.sender.name)}>
                                                                            <i className="fa-solid  me-2"></i> Reply</a>
                                                                    </li>
                                                                    <li>
                                                                        <a className="dropdown-item d-flex align-items-center clickable" onClick={() => deleteMessageEveryOne(value.id)}>
                                                                            <i className="fa-solid me-2" /> Delete for everyone</a>
                                                                    </li>
                                                                    <li>
                                                                        <a className="dropdown-item d-flex align-items-center clickable" onClick={() => deleteMessage(value.id, index)}>
                                                                            <i className="fa-solid  me-2"></i> Delete Message</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        }

                                                </li>
                                                :
                                                <li key={value.id} className="you d-flex justify-content-start">
                                                    <figure>
                                                        <div
                                                            className="user-picture-container rounded-circle me-2"
                                                            style={{ background: `url(${value.sender.avatar})` }}
                                                        />
                                                    </figure>
                                                    <div className="chat-dropEnd">
                                                        <div className="btn-group dropend">
                                                            <button type="button" className="btn   p-2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                                            </button>
                                                            <ul className="dropdown-menu" >
                                                            <li>
                                                                    <a className="dropdown-item d-flex align-items-center" href="javascript:void(0)">
                                                                        <i className="fa-solid  me-2"></i>Message Info</a>
                                                                </li>
                                                                <li>
                                                                    <a className="dropdown-item d-flex align-items-center" href="javascript:void(0)" onClick={() => replyMessage(value.id, value.message, value.sender.name)}>
                                                                        <i className="fa-solid  me-2"></i>Reply</a>
                                                                </li>
                                                                <li>
                                                                        <a className="dropdown-item d-flex align-items-center clickable" onClick={() => deleteMessageEveryOne(value.id)}>
                                                                            <i className="fa-solid me-2" /> Delete for everyone</a>
                                                                    </li>
                                                                <li>
                                                                    <a className="dropdown-item d-flex align-items-center " onClick={() => deleteMessage(value.id, index)} href="javascript:void(0)">
                                                                        <i className="fa-solid me-2"></i>Delete Message</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                        <div className="meeting-Invitation w-100">
                                                    <div className="text-box message-text">
                                                        {value.file_name != null ?
                                                            <>
                                                                <MessageAction value={value} />
                                                                <p dir="auto">
                                                                        {value.message}
                                                                        
                                                                        {value.message_type == 4 ?
                                                                            <div>
                                                                                <audio
                                                                                    controls
                                                                                    src={value.file_name}>
                                                                                        
                                                                                </audio>
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                            <img className="d-block" src={value.file_name} alt={value.file_name} />
                                                                            <a className="text-primary" target="_blank" href={value.file_name}>Download</a>
                                                                            </div>
                                                                        }
                                                                    </p>
                                                                {value.url_details != null ?
                                                                    <>
                                                                        <a href={value.url_details['url']} target="_blank">
                                                                            <div className="link-msg-img">
                                                                                <img class=""
                                                                                    src={value.url_details['image']}
                                                                                    alt="" />
                                                                            </div>
                                                                            <p class="mt-2 link-msg-title">{value.url_details['title']}</p>
                                                                            <p className="mt-2 link-msg-dsc">{value.url_details['description']}</p>
                                                                        </a>
                                                                    </>
                                                                    :
                                                                    ""
                                                                }

                                                                <span> {value.created_at}</span>
                                                            </>
                                                            : value.message_type === 10 ?
                                                                <>
                                                                    <div className=" meeting-Invitation w-100">
                                                                        <div className="text-box message-text">
                                                                            <h6 className="mb-1">Invitation for Meeting</h6>
                                                                            <p>
                                                                                Please Join using this link or click to join
                                                                                now
                                                                            </p>
                                                                            <p className="text-primary mt-3">{value.message}</p>
                                                                        </div>
                                                                        <div className="ms-3 mt-2">
                                                                            <a href={value.message} target={'_blank'}
                                                                                className="btn btn-primary btn-sm"><i
                                                                                    className="fas fa-plus me-1" /> Join Now
                                                                            </a>
                                                                        </div>

                                                                    </div>
                                                                    <span> {value.created_at}</span>
                                                                </>
                                                                :

                                                                <>
                                                                {value.is_reply == 1 ?
                                                                <p className="mb-2 you-text">{value.old_message}</p>
                                                                :''}
                                                                    <p dir="auto" className={`${value.is_reply == 1 ? 'replay-text':''}`}>
                                                                        {value.message}

                                                                    </p>
                                                                    {value.url_details != null ?
                                                                        <>
                                                                            <a href={value.url_details['url']}
                                                                                target="_blank">
                                                                                <div className="mt-2 link-msg-img">
                                                                                    <img class=""
                                                                                        src={value.url_details['image']}
                                                                                        alt="" />
                                                                                </div>
                                                                                <p class="mt-2 link-msg-title">{value.url_details['title']}</p>
                                                                                <p className="mt-2 link-msg-dsc">{value.url_details['description']}</p>
                                                                            </a>
                                                                        </>
                                                                        :
                                                                        ""
                                                                    }
                                                                    <span> {value.created_at}

                                                                    </span>
                                                                </>

                                                        }

                                                    </div>
                                                    </div>
                                                </li>
                                            }

                                        </>

                                    ))}

                            </ul>
                            <div className="messageEndArea" ref={messagesEndRef} />

                        </div>
                    </div>

                    {reply_message_enable ?
                        <div className="text-reply">
                            <div className="d-flex reply-flex">
                                <div className="text-reply-head">
                                    <span className="replying d-block">{reply_to}</span>
                                    <p className="replying-text">{reply_message}</p>
                                </div>
                                <div className="text-reply-cancel">
                                    <a href="javascript:void(0)" onClick={() => replyMessageCancel()}><i className="fa-solid fa-xmark"></i></a>
                                </div>

                            </div>
                        </div>
                        : ''}

                    <div className={`message-writing-box ${member_role?.role_id==0?'d-none':''}`}>
                        <form method="post" onSubmit={handleSubmit} onDrop={handleDrop} onDragOver={handleDragOver} ref={dropZoneRef}>
                            <div className="text-area">
                                <input type="text"
                                    placeholder={`${isTyping ? 'typing....' : 'write your message here..'}`}
                                    onKeyDown={userTyping}
                                    onChange={(e) => setText(e.target.value)} />
                                <div
                                    className="d-flex message-writing-box-btn align-items-center justify-content-between">
                                    <div className="voice-record">
                                        {isRecorde ?
                                            <a className={'clickable'} onClick={stopRecording}>
                                                <i className="fa-solid fa-stop text-danger" />
                                            </a>

                                            :
                                            <a className={'clickable'} onClick={startRecording}>
                                                <i className="fa-solid fa-microphone" />
                                            </a>
                                        }


                                    </div>
                                    <div className="attach-file">
                                        <label className="fileContainer clickable" onClick={() => clickPhoto()}>
                                            <i className="fa-solid fa-image" />
                                            {/*<input type="file" multiple={true} accept="audio/*,video/*,image/*"*/}

                                            {/*       onChange={uploadHandler} ref={fileInput}/> */}
                                            <input type="hidden" multiple={true} accept="audio/*,video/*,image/*"
                                                onClick={() => clickPhoto()} />
                                        </label>
                                    </div>
                                    <button type="submit">
                                        <i className="fa-solid fa-paper-plane" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-3 d-none d-sm-block g-0 ">
                    <GroupChatFile members={members} user={user} recentChatFile={chatHistory} />
                </div>
            </div>
            <FileSend dargDrop={is_darg_drop} dargDropFile={darg_drop_file} isModalVisible={post_modalDialog} handleShowB={() => setPostShareModalDialog(false)} to_id={id}
                childToParent={childToParent} is_group='1' />
        </>)
}
export default UserInbox;