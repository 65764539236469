
import { createSlice } from '@reduxjs/toolkit';

export const notifyIdSlice = createSlice({
    name:'ActiveNotify',
    initialState:{
        loading: false,
        data: null,
        error: false,
    },
    reducers:{
        ACTIVE_Notify_FETCH_START:(state)=>{
            state.loading=true
        },
        ADD_Notify_SUCCESS:(state,action)=>{
            state.data=action.payload
        },
        ACTIVE_Notify_FETCH_STOP:(state)=>{
            state.loading=false
        },
    }
});

export const { ACTIVE_Notify_FETCH_START,ADD_Notify_SUCCESS,ACTIVE_Notify_FETCH_STOP }=notifyIdSlice.actions;
export default notifyIdSlice.reducer;