export const meeting_history = '/v2/meeting_history';
export const comment_reply = '/v2/comment_reply';
export const unsubcribe_email = '/v2/unsubcribe_email';
export const upcoming_meeting = '/v2/upcoming_meeting';
export const get_all_post_web = '/v2/get_all_post_web';
export const post_comments = '/v2/post_comments';
export const post_likes = '/v2/post_likes';
export const get_post_sync = '/v2/get_post_sync';
export const suggested_videos = '/suggested_videos';
export const story_show = '/v2/story/show';
