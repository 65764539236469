import React, { useState, useCallback, useRef } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import API from "../../Network/API";
import { useDispatch, useSelector } from "react-redux";
import Agent from "../../Network/Agent";
import { SUGGESTED_VIDOE_DELETE_COMMENT, SUGGESTED_VIDOE_POST_FETCH_COMMENT_ADDON } from "../../Reducer/suggestedVideoReducer";
import { FPOST_FETCH_COMMENT_ADDON } from "../../Reducer/friendProfileReducer";
import { SINGLE_DELETE_COMMENT, SINGLE_POST_FETCH_COMMENT_ADDON } from "../../Reducer/singlePostReducer";
export default function SuggestRealModal(props) {
    const selector_video = useSelector((state) => state.suggestedVideoReducer)
    const post_data = selector_video?.post_data;

    const [comment_love_react, setCommentLoveReact] = useState(false);
    const [reply_comment_delete, setReplyCommentDelete] = useState(false);
    const [reply_comment_id, setReplyCommentID] = useState(false);
    const [comment_love_react_add, setCommentLoveReactAdd] = useState(0);
    const [write_comment, setWriteComment] = useState(null);
    const [reply_comment, setReplyComment] = useState(null);
    const [set_like, setLike] = useState(0);
    const [love_react, setLoveReact] = useState(false);
    const [p_id_like, setPIDLike] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [current_index, setCurrentIndex] = useState(0);
    const [page, setPage] = useState(1);
    const [p_id_com, setPIDCom] = useState(0);
    const [last_comments, setLastComments] = useState([]);
    const [set_comment, setComment] = useState(0);
    const [currentPost, setCurrentPost] = useState(null);
    const [nextPostId, setNextPostId] = useState(null);
    const [prevPostId, setPrevPostId] = useState(null);
    const dispatch = useDispatch();

    const [postId, setPostId] = useState(0);
    const [post_modalDialog, setPostShareModalDialog] = useState(false);
    const [show, setShow] = useState(false);
    const [show_comment_box, setShowCommentBox] = useState(false);

    const [is_comment_loading, setCommentLoading] = useState(false);
    const [reply_com_id, setReplyComID] = useState(0);
    const [comment_id, setCommentID] = useState(0);
    const [is_com_delete, setISComDelete] = useState(false);
    const [comment_page, setCommentPage] = useState(2);

    useEffect(() => {
        if (Number(page) != 1) {
            Agent.SuggestVideos.loadMore(page, dispatch);
        }
    }, [page]);

    useEffect(() => {
        fetchCurrentPost(props?.postId);
        fetchNextAndPrevPostIds(props?.postId);
    }, [props?.postId, post_data]);

    const fetchCurrentPost = (postId) => {
        const post = post_data.find((post) => post.id === postId);
        setCurrentPost(post);
    };

    const fetchNextAndPrevPostIds = (currentPostId) => {
        const currentIndex = post_data.findIndex((post) => post.id === currentPostId);
        setCurrentIndex(currentIndex);
        // const nextIndex = (currentIndex + 1) % post_data.length;
        // const prevIndex = (currentIndex - 1 + post_data.length) % post_data.length;
        // setNextPostId(post_data[nextIndex].id);
        // setPrevPostId(post_data[prevIndex].id);
    };

    const handleNextClick = () => {
        if (post_data.length < current_index) {
            return;
        }
        const nextIndex = (current_index + 1) % post_data.length;
        setCurrentIndex(current_index + 1);
        setNextPostId(post_data[nextIndex].id);
        fetchCurrentPost(post_data[nextIndex].id);
        const lastPageIndex = post_data.length - 1;

        if (lastPageIndex >= 0 && current_index == lastPageIndex) {
            console.log(lastPageIndex);
            setPage(page + 1)
        }
        // fetchNextAndPrevPostIds(nextPostId)
    };

    const handlePrevClick = () => {
        if (current_index < 0) {
            return;
        }
        const prevIndex = (current_index - 1 + post_data.length) % post_data.length;
        setCurrentIndex(current_index - 1);
        setPrevPostId(post_data[prevIndex].id);
        fetchCurrentPost(post_data[prevIndex].id);
        // fetchNextAndPrevPostIds(nextPostId)
    };



    const postLike = (post_id) => {
        setLoading(true)
        setLoveReact(true)
        setLike(1)
        setPIDLike(post_id)
        API.post("/post_likes", {
            post_id: post_id,
            react_icon: 1,
        }).then((response) => {
            setLoading(false)
            setLoveReact(true)
            if (response.data.success === true) {
                // toast.success('Post loved Successfully');
            } else {
                setLoveReact(false)
            }
        });
    }

    const submit_comment = (p_id) => {
        if (write_comment === null) {
            toast.error('Enter something!');
            return false;
        }
        setLoading(true)
        setComment(1)
        API.post("/post_comments", {
            post_id: p_id,
            comment: write_comment,
        }).then((response) => {
            setLoading(false)
            setWriteComment("")
            if (response.data.success === true) {
                dispatch(SUGGESTED_VIDOE_POST_FETCH_COMMENT_ADDON(response.data.data))
                dispatch(FPOST_FETCH_COMMENT_ADDON(response.data.data))
                dispatch(SINGLE_POST_FETCH_COMMENT_ADDON(response.data.data))
                setPostId(p_id)
                toast.success('comment success');
            }
        });

    }


    const toggleComment = (post_id) => {
        setShowCommentBox(!show_comment_box)
        setPostId(post_id)
    };


    const commentLike = (post_id, comment_id) => {
        setLoading(true)
        setCommentLoveReact(true)
        setPIDCom(comment_id)
        setCommentLoveReactAdd(1)
        API.post("/comment_like", {
            post_id: post_id,
            comment_id: comment_id,
            react_icon: 1,
        }).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
                // toast.success('Comment loved Successfully');
            }
        });
    }

    const commentsReplyDelete = (comments_id) => {
        setLoading(true)
        API.delete("/reply_comment_delete/" + comments_id).then((response) => {
            if (response.data.success === true) {
                setLoading(true)
                setReplyCommentDelete(true)
                setReplyCommentID(comments_id)
                toast.success('Comment deleted');
            }
        });
    }

    const handleReplyMessage = (p_id, cm_id) => {
        setLoading(true)
        setReplyComID(cm_id)
        API.post("/comment_reply", {
            post_id: p_id,
            comment_id: cm_id,
            comment: reply_comment,
        }).then((response) => {
            setLoading(false)
            setReplyComment('')
            if (response.data.success === true) {

                // toast.success('Reply sent');
            }
        });
    }

    const commentsDelete = (comments_id, post_id) => {
        setLoading(true)
        API.delete("/comment_delete/" + comments_id).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
                setISComDelete(true)
                setCommentID(comments_id)
                setPostId(post_id)
                setComment(-1)
                const comPayLod={
                    comment_id:comments_id,
                    post_id:post_id,
                }
                dispatch(SUGGESTED_VIDOE_DELETE_COMMENT(comPayLod))
                dispatch(SINGLE_DELETE_COMMENT(comPayLod))
                // toast.success('Comment deleted');
            }
        });
    }

    return (<>
        <Modal
            isOpen={props.isModalVisible}
            size=""
            toggle={props.handleShowB}
            className={''} backdrop="static"
        >
            <div className="modal-content bg-dark vh-100 position-relative modal-content-height-cl">
                <div className="reels-btn-wrap reels-btn-pre-wrap position-absolute">
                    <button type="button" className="btn btn-dark rounded-circle btn-lg reels-btn-next" onClick={handlePrevClick}>
                        <i className="fa-solid fa-angle-left" />
                    </button>
                </div>
                <div className="modal-header border-0 reels-modal_header">
                    <button type="button" className="btn-close story-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleShowB}>
                        <i className="fa-solid fa-xmark" />
                    </button>
                </div>
                <div className="modal-body overflow-hidden p-0">
                    <div className="w-100 h-100 position-relative">

                        <video id="reels_video" key={currentPost?.videos?.[0]?.video_stream} controls  loop className="w-100 h-100" autoPlay >
                            <source src={currentPost?.videos?.[0]?.video_stream} type="video/mp4" />
                        </video>

                        <div id="reels_right_action_nav">
                            <ul className="list-unstyled m-0 p-0">

                                <li>
                                    {currentPost?.is_like ?
                                        <i className="fa-solid fa-heart i-loved" />
                                        :
                                        <>
                                            <i onClick={() => postLike(currentPost?.id)} className={`fa-solid fa-heart clickable ${love_react && p_id_like === currentPost?.id ? 'i-loved' : ''}`} />
                                        </>
                                    }

                                    <span>{currentPost?.totalLikes + set_like}</span>
                                </li>
                                <li>
                                    <i className="fa-solid fa-comment" />
                                    <span>{currentPost?.totalComments + set_comment}</span>
                                </li>
                                <li>
                                    <i className="fa-solid fa-share-nodes" />
                                </li>
                            </ul>
                        </div>
                        
                        <div id="reels_info">
                            <h6 className="mb-2 text-white mx-w-75 reel-video-title">{currentPost?.is_shared_post ? currentPost?.self_details : currentPost?.details}</h6>
                            <div className="d-flex align-items-center mb-2">
                                <div className="reels_user-img me-2">
                                    <div className="user-picture-container rounded-circle border border-2 border-white" style={{ backgroundImage: `url(${currentPost?.avatar})`, objectFit: 'fill' }} />
                                </div>
                                <div className="reels_video-info">
                                    <div id="reels_user-info">
                                        {currentPost?.post_by}
                                    </div>
                                </div>
                            </div>
                            <div className="reel-replay position-relative ">
                                <input className="form-control reel-replay-input rounded-pill " type="text" placeholder="Write Comments..." aria-label="default input example" value={write_comment} onChange={(e) => setWriteComment(e.target.value)}/>
                                <i className="fa-solid fa-paper-plane send-icon" onClick={() => submit_comment(currentPost?.id)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reels-btn-wrap reels-btn-next-wrap position-absolute">
                    <button type="button" className="btn btn-dark rounded-circle btn-lg reels-btn-next" onClick={handleNextClick}>
                        <i className="fa-solid fa-angle-right" />
                    </button>
                </div>
            </div>
        </Modal>
    </>
    )
}