import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTIVE_Notify_FETCH_STOP } from "../Reducer/ActiveNotifyReducer";

export default function Notify(props){
    const [is_show, setShow] = useState(false);
    const selector = useSelector((state) => state.activeNotifyReducer)
    const dispatch=useDispatch();
    const hideMessage=()=>{
      dispatch(ACTIVE_Notify_FETCH_STOP());
    }



    return (
        <>
     <div className={`popup-notification ${selector?.loading?'d-block':'d-none'}`}>
        <div className="popup-notification-wrap shadow">
          <div className="popup-notification-header">
            <div className="popup-notification-header-close-btn">
              <a href="javascript:void(0)" onClick={()=>hideMessage()}><i className="fa-solid fa-xmark" /></a>
            </div>
          </div>
          <div className="popup-notification-body d-flex">
            <div className="popup-notification-user-info">
              <div className="popup-notification-user-info-thamb">
                <div className="image">
                  <a href="javascript:void(0)">
                    <div className="user-picture-container rounded-circle" style={{background: `url("${selector?.data?.avatar}")`}} />
                  </a>
                  <div className="popup-notification-reaction">
                    <div className="popup-notification-reaction-icon">
                      <i className="fa-solid fa-thumbs-up" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="popup-notification-content">
              <p className="mb-2">
                {selector?.data?.title}
                {/* <strong>Md. Shakil, Md.Kamrul Hand</strong> and <strong>73 Other popple</strong>
                recact your photos */}
                
              </p>
              <p>{selector?.data?.details}</p>
              <small className="text-primary">A few Second ago</small>
            </div>
          </div>
        </div>
      </div>
    </>
    );
}