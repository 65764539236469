import React from 'react'
import VideoPlayer from "react-video-js-player";
import {Link} from "react-router-dom";

const SuggestVideo = ({data}) => (

   <>
       <div className="single-video-card bg-white shadow-sm" key={Math.random()}>
           <div className="video-image">
               {data.videos?.length > 0 ?
                   <div>
                       {data.videos?.slice(0).map((getVideo, i) => (
                           <video key={i}
                                        controls={false}
                                        preload="metadata"
                                        height="200"
                                        oncontextmenu="return false;"
                                        hideControls={['playbackrates']}
                                        src={`${getVideo.video_stream}`}
                                        className="w-100 video-js vjs-theme-sea suggested-side-video"
                           />

                       ))}
                       <div className="icon">
                           <a href={`/video/${data?.id}`} className="video-btn popup-youtube">
                               <i className="fa-solid fa-circle-play"/>
                           </a>
                       </div>

                   </div>
                   : ''}
           </div>
           <div className="video-info-area">
               <div className="video-info">
                   <div className="d-flex justify-content-between align-items-start">
                   <div className="d-flex justify-content-start">
                       <div className="video-user-container">
                           <Link to={`/contact/${data?.user_id}`}>
                           <div className="user-picture-container rounded-circle video-user-35" style={{backgroundImage: `url(${data?.avatar})`}} />
                           </Link>
                       </div>
                       <div className="text">
                           <h6 className="ms-2 me-2">
                               <a href={`/video/${data?.id}`}>{data?.details ? data.details : data.post_by }</a>
                           </h6>
                       </div>
                   </div>
               </div>
               </div>
               <ul className="meta-wrap d-flex signle-v-meta-wrap">
                   <li className="react">
                       <a href={`/video/${data.id}`}><i className="fa-solid fa-heart me-2" /><span className="me-1">{data?.totalLikes}</span><span>Love</span></a>
                   </li>
                   <li className="comment">
                       <a href={`/video/${data.id}`}><i className="fa-solid fa-comment-dots me-2" /><span className="me-1">{data?.totalComments}</span><span>Comment</span></a>
                   </li>
                   <li className="share">
                       <a href={`/video/${data.id}`}><i className="fa-solid fa-share-nodes me-2" /><span className="me-1">0</span><span>Share</span></a>
                   </li>
               </ul>
           </div>
       </div>
       </>
)
export default React.memo(SuggestVideo)