import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import API from "../../Network/API";
import ShareMeeting from "../Modal/ShareMeeting";
import PostLoader from '../../assets/img/loader.gif';
export default function MeetingDetails() {
  const myArr = JSON.parse(localStorage.getItem('user_data'));
  const [meetingData, setMeetingData] = useState([]);
  const [progress, setProgress] = useState(false);
  let { room } = useParams();
  const [modalDialog, setModalDialog] = useState(false);
  const [sc_meet, setScMeet] = useState(null);


  const toggleModal = (meeting_room) => {
    setScMeet(meeting_room)
    setModalDialog(!modalDialog)
};

  useEffect(() => {
    setProgress(true)
    API.get('/v1/meeting_details/' + room).then((response) => {
      setMeetingData(response.data.data);
      setProgress(false)
    }).catch((error) => {
      setProgress(true)
    });

  }, [room]);
  return (
  <>

{progress? 
           <div className='content' style={{ margin: 'auto', padding: 'auto', textAlign: 'center' }}>
           <img src={PostLoader} alt='Post loader' height={80} width={80} />
       </div>
        :

    <>
    <div className="upcoming-meeting-page-banner-section">
      <div className="container">
        <div className="row justify-content-center text-center align-items-center">
          <div className="col-md-7">
            <div className="upcoming-meeting-page-banner-content">
              <h2 className="title text-white">{meetingData?.room_title}</h2>
              <p>{meetingData?.note}</p>
              <div className="upcoming-meeting-start-date-time d-flex justify-content-center align-items-center">
                <div className="upcoming-meeting-start-date">
                  {meetingData?.start_text}
                </div>
                <div className="upcoming-meeting-start-driver" />
                <div className="upcoming-meeting-start-date">
                  {meetingData?.start_time_text}
                </div>
              </div>

              {meetingData?.user_id == myArr.id ?

                <div className=" upcoming-meeting-user-start-img">
                  <ul className="d-flex m-0 p-0 justify-content-center align-items-center">
                    {meetingData?.total_invites > 0 && meetingData?.users.map((user, index) => (
                      <li>
                        <Link to={`/contact/${user.id}`}><div className="user-picture-50 rounded-circle" style={{ background: `url(${user.avatar})` }} /></Link>
                      </li>
                    ))}
                    <li>
                      <a className="clickable" onClick={()=>toggleModal(meetingData)}>
                        <div className="upcoming-meeting-user-start-add"><span className="fa fa-plus" /></div>
                      </a>
                    </li>
                  </ul>
                </div>
                :
                <div className=" upcoming-meeting-user-start-img">
                  <ul className="d-flex m-0 p-0 justify-content-center align-items-center">
                    <li>
                      <div className="text text-white">Hosted by</div>
                    </li>
                    <li>
                      <a href="javascript:void(0)"><div className="user-picture-50 rounded-circle" style={{ background: `${meetingData?.avatar}` }} /></a>
                    </li>
                    <li>
                      <div className="text text-white">{meetingData?.created_by}</div>
                    </li>
                  </ul>
                </div>
              }

              <div className="upcoming-meeting-start-btn-area">
                <a className="btn upcoming-meeting-start-btn  " href={`https://connectsapp.com/meeting/${meetingData?.room_name}`} target="_blank" role="button">
                  {meetingData?.user_id == myArr.id ? 'Start meeting' : 'Join meeting'} <span className="fa fa-arrow-right ms-2" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="upcoming-meeting-page-banner-details-image mt-4 mb-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <a href="javascript:void(0)">
                <img src={`${meetingData?.cover==''? meetingData?.cover:'/assets/img/Upcoming meeting details-1.jpg'}`} alt="Blog" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ShareMeeting isModalVisible={modalDialog} meetingRoom={sc_meet} handleShowB={() => setModalDialog(false)}/>
    </>
            }

  </>)
}