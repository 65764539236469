import React, {Suspense,lazy} from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import {BrowserRouter,  Outlet,Route, Routes} from "react-router-dom";

// import Login from "./Auth/Login";
import LoginVerify from "./Auth/LoginVerify";
import Nopage from "./Page/Nopage";

import MainSectionProfile from "./Component/Profile/MainSectionProfile";
import MainSectionFriendProfile from "./Component/Profile/MainSectionFriendProfile";


import Contact from "./Component/Contact";
import Helps from "./Component/Pages/Helps";
import Terms from "./Component/Pages/Terms";
import Policy from "./Component/Pages/Policy";
import Meeting from "./Component/Meeting/Meeting";
import MeetingRoom from "./Component/Meeting/MeetingRoom";
import SinglePost from "./Component/SinglePost";
import MeetingHistory from './Component/Meeting/MeetingHistory';
import MeetingSchedule from './Component/Meeting/MeetingSchedule';
import MeetingWaiting from './Component/Meeting/MeetingWaiting';
import SingleVideo from './Component/SingleVideo';
import {createBrowserHistory} from "history";
import AllStory from "./Component/Story/AllStory";
import UserInbox from "./Component/Messages/UserInbox";
import GroupInbox from "./Component/Messages/GroupInbox";
import MainSectionMessages from "./Component/Messages/MainSectionMessages";
import DefaultMessageBox from "./Component/Messages/DefaultMessageBox";
import NameVerify from "./Auth/NameVerify";
import Notification from "./Component/Notification";
import ScrollToTop from "./CustomHooks/scrollToTop";

// Required for Redux store setup
import { Provider, useSelector } from 'react-redux'
import configureStore from './store';
import Loader from './Network/Loader';
import Realtime from './Network/Realtime';
import createSocketConnection from './Component/Messages/SocketConnection';
import MeetingDetails from './Component/Meeting/MeetingDetails';
import UnsubscribeEmail from './Component/UnsubscribeEmail';
import SingleStory from './Component/Story/SingleStory';
import SingleRealVideo from './Component/SingleRealVideo';

//Code Splitting/ Lazy loading
const Login = lazy(()=>import("./Auth/Login"));
const Home = lazy(()=>import("./Component/Home"));
const Video = lazy(()=>import("./Component/Video"));
createSocketConnection();
const customHistory = createBrowserHistory();

ReactDOM.render(

        <Provider store={configureStore}>
        <BrowserRouter history={customHistory}>
        <Suspense fallback={<Loader/>}>
            <Realtime/>
            <ScrollToTop />
            <Routes>
               
                <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<LoginOutlet/>}>
                    <Route path="" element={<Login/>}/>
                </Route>
                <Route exact path={`${process.env.PUBLIC_URL}/login-verify`} element={<LoginOutlet/>}>
                    <Route path="" element={<LoginVerify/>}/>
                </Route>

                <Route exact path={`${process.env.PUBLIC_URL}/name-verify`} >
                    <Route path="" element={<NameVerify/>}/>
                </Route>

                <Route exact path={`${process.env.PUBLIC_URL}/page/terms`} >
                    <Route path="" element={<Terms/>}/>
                </Route>

                <Route exact path={`${process.env.PUBLIC_URL}/page/helps`} >
                    <Route path="" element={<Helps/>}/>
                </Route>

                <Route exact path={`${process.env.PUBLIC_URL}/page/policy`} >
                    <Route path="" element={<Policy/>}/>
                </Route>

                <Route path="*" element={<Nopage/>}/>

                <Route path={`${process.env.PUBLIC_URL}/meeting/:room`} element={<PrivateOutlet/>}>
                    <Route path="" element={<MeetingRoom/>}/>
                </Route>

                <Route exact path="/messages" element={<MainSectionMessages/>}>
                    <Route path="/messages" element={<PrivateOutlet/>}>
                        <Route index element={<DefaultMessageBox/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/messages/thread/:id`} element={<PrivateOutlet/>}>
                        <Route path="" element={<UserInbox/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/messages/group/:id`} element={<PrivateOutlet/>}>
                        <Route path="" element={<GroupInbox/>}/>
                    </Route>
                </Route>

                <Route exact path="/" element={<App/>} >

                    <Route element={<PrivateOutlet/>} loading>
                        <Route index element={<Home/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/me`} element={<PrivateOutlet/>}>
                        <Route path="" element={<MainSectionProfile/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/contact/:id`} element={<PrivateOutlet/>}>
                        <Route path="" element={<MainSectionFriendProfile/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/contacts`} element={<PrivateOutlet/>}>
                        <Route path="" element={<Contact/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/terms`} element={<PrivateOutlet/>}>
                        <Route path="" element={<Terms/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/policy`} element={<PrivateOutlet/>}>
                        <Route path="" element={<Policy/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/meeting`} element={<PrivateOutlet/>}>
                        <Route path="" element={<Meeting/>}/>
                    </Route>

                    <Route exact path={`${process.env.PUBLIC_URL}/post/:id`}>
                        <Route exact path="" element={<SinglePost/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/meeting-history`} element={<PrivateOutlet/>}>
                        <Route path="" element={<MeetingHistory/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/meeting-schedule`} element={<PrivateOutlet/>}>
                        <Route path="" element={<MeetingSchedule/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/meeting-waiting/:room`} element={<PrivateOutlet/>}>
                        <Route path="" element={<MeetingWaiting/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/meeting-details/:room`} element={<PrivateOutlet/>}>
                        <Route path="" element={<MeetingDetails/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/all-story`} element={<PrivateOutlet/>}>
                        <Route path="" element={<AllStory/>}/>
                    </Route>

                    <Route path={`${process.env.PUBLIC_URL}/videos`} element={<PrivateOutlet/>}>
                        <Route path="" element={<Video/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/video/:id`} element={<PrivateOutlet/>}>
                        <Route path="" element={<SingleVideo/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/notifications`} element={<PrivateOutlet/>}>
                        <Route path="" element={<Notification/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/unsubscribe-reason`} element={<PrivateOutlet/>}>
                        <Route path="" element={<UnsubscribeEmail/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/stories/:id`} element={<PrivateOutlet/>}>
                        <Route path="" element={<SingleStory/>}/>
                    </Route>
                    <Route path={`${process.env.PUBLIC_URL}/shorts/:id`} element={<PrivateOutlet/>}>
                        <Route path="" element={<SingleRealVideo/>}/>
                    </Route>

                </Route>
                
            </Routes>
            </Suspense>
        </BrowserRouter>
        </Provider>
    
    ,
    document.getElementById('root')
);

function useAuth() {
    if (localStorage.getItem('connect_token') && localStorage.getItem('connect_token') !== 'undefined') {
        return true;
    }
    return false;
}


function PrivateOutlet() {
    
    const auth = useAuth();
    if(!auth){
        localStorage.setItem("url", window.location.href);
    }else{
        if(localStorage.getItem('profile_name')==null || localStorage.getItem('profile_name')==''){
            //  localStorage.setItem("url", window.location.href);
             return  window.location.href = "/name-verify";
        }
        localStorage.setItem("url",null);
    }
    return auth ? <Outlet/> : window.location.href = "/login";
}

function LoginOutlet() {
    const auth = useAuth();
    return auth ? window.location.href = "/" : <Outlet/>;
}

reportWebVitals();
serviceWorker.register();