export default function UnsubscribeEmail() {
    return (
        <>
            <div className="container-fluid">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-10">
                        <div className="story-postbox news-feed-area">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <div className="card border-0 shadow py-4">
                                        <div className="card-body">
                                            <h6 className="mb-3">Unsubscribe</h6>
                                            <p className="mb-2 text-dark">Your Email Address <strong>shafi@gmail.com</strong></p>
                                            <p className="unsubscribe-text text-dark">Your will not receive any more Emails form <br /><spn className="text-primary">shafi@gmail.com</spn></p>
                                         
                                            <a href="javascript:void(0)" className="btn btn-primary mb-2">Unsubscribe</a>
                                            <p><a href className="text-danger">Report Spam</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}