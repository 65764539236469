import React, {Fragment, useEffect, useState, memo} from "react";
import API from "../Network/API";


import ContentLoader from "react-content-loader"
import { Link } from "react-router-dom";
export default function Contact(props) {
    const [users,setUsers] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [demo_data, setDatas] = useState([{id:1},{id:2},{id:3},{id:4},{id:5},{id:6},{id:7},{id:8},{id:9},{id:10}]);
    const [progress, setProgress] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(1);
    const [next_page, setNextPage] = useState('/friend_list_web');
    const [nextPageUrl, setNextPageURL] = useState(null);
    const [lengthData, setDataLength] = useState(0);

    useEffect(() => {
        fetchRepositories()
    }, []);

    function fetchRepositories() {

        setProgress(true)

        API.get(next_page, {
            'offset': users.length
        }).then((response) => {
            // console.log(response.data.data)
            const paginator = response.data.data;
            setDataLength(paginator.total)
            if (paginator.users.length) {
                setUsers(paginator.users)
                setProgress(false)
                setNextPageURL(paginator.next_page_url)
                setPage((page+1))
            }

        }).catch((error) => {
            setProgress(false)

        });

    }

    function fetchRepositoriesNext() {

        setLoading(true)

        API.get(next_page+'?page='+page, {
            'offset': users.length
        }).then((response) => {
            setLoading(false)
            const paginator = response.data.data;
            setDataLength(paginator.total)
            if (paginator.users.length) {
                setUsers([...users, ...paginator.users])
                setNextPageURL(paginator.next_page_url)
                setPage((page+1))
                setIsFetching(false)
            }
        }).catch((error) => {
            setLoading(false)
        });

    }
    return(
        <>
            <div className="row d-flex justify-content-center">
                <div className="col-md-10">
            <div className="friend_list-page">
                <div className="row">
                    <div className="friend_list-heading">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="friend_list-heading-text">
                                <h4>Contacts<span className="friend_list-count">({lengthData})</span></h4>
                            </div>
                            <div className="message-people-srch">
                                <div className="messages-search-box">
                                    <form>
                                        <input type="text" className="input-search"
                                               placeholder="Search..."/>
                                        <button type="submit">
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-2">
                    {progress && demo_data?.map((post,i) => (
                        <div className="col-md-6">
                            <ContentLoader
                                speed={2}
                                width={400}
                                height={120}
                                viewBox="0 0 400 120"
                                backgroundColor="#eeecec"
                                foregroundColor="#eae6e6"
                                {...props}
                            >
                                <circle cx="33" cy="54" r="22" />
                                <rect x="63" y="40" rx="0" ry="0" width="239" height="8" />
                                <rect x="65" y="63" rx="0" ry="0" width="198" height="7" />
                                <rect x="0" y="88" rx="0" ry="0" width="314" height="16" />
                                <rect x="0" y="9" rx="0" ry="0" width="314" height="16" />
                            </ContentLoader>
                        </div>
                    ))}
                    {!progress && users?.map((user, index) => (
                        <div className="col-md-6">
                            <div className="bg-white shadow-sm single-friend-list ">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="friend-list-info d-flex">
                                        <div className="friend-list-img">
                                            <div className="user-picture-container rounded-circle"
                                                 style={{backgroundImage: `url(${user.avatar ?? "https://via.placeholder.com/50x50.png"})`}}></div>
                                        </div>
                                        <div className="friend-list-text">
                                            <Link to={`/contact/${user.user_id}`}><h4>{user.name}</h4></Link>
                                            <p>{user.email}</p>
                                        </div>
                                    </div>
                                    <div className="friend-list-button">
                                        <div className="d-flex">
                                            <a href="javascript:void(0)" className="friend-list-vcall">
                                                <i className="fa-solid fa-video"></i>
                                            </a>
                                            <a href="javascript:void(0)" className="friend-list-msg">
                                                <i className="fa-solid fa-message"></i>
                                            </a>
                                            <div className="global-dropdown">
                                                <div className="dropdown">
                                                    <button className="dropdown-toggle"
                                                            type="button" data-bs-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"><i
                                                        className="fa-solid fa-ellipsis-vertical"></i>
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li><a
                                                            className="dropdown-item d-flex align-items-center"
                                                            href="javascript:void(0)"><i
                                                            className="fa-solid fa-user-slash"></i>
                                                            Unfollow</a></li>
                                                        <li><a
                                                            className="dropdown-item d-flex align-items-center"
                                                            href="javascript:void(0)"><i
                                                            className="fa-solid fa-user-slash"></i> Block
                                                        </a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    ))}

                    { nextPageUrl!=null?
                        <div className={'text-center'} style={{marginBottom:'10px'}}>

                            <button  className={'btn btn-lg btn-primary'} onClick={fetchRepositoriesNext}>Load More {isLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span></span>
                            )}</button>
                        </div>
                        :''
                    }
                </div>
            </div>
                </div>
                </div>
                </>
    )
}